import React from 'react';

import './style.scss';

const Select = ({ options, ...rest }) => {
    return (
        <select {...rest}>
            {options.map((option, index) => {
                return (
                    <option key={option.id || index} value={option.value}>{option.text}</option>
                )
            })}
        </select>
    )
}

export default Select;

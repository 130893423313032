import {useState, useLayoutEffect, React, useEffect} from 'react';

import BoxBg from '../../components/BoxBg';
import BoxText from '../../components/BoxText';
import ColumnsRow from '../../components/ColumnsRow';
import Popup from '../../components/Popup/Popup';

import axios from 'axios'

import { useStytch } from '@stytch/stytch-react';
import { useStytchSession } from '@stytch/stytch-react';

const method_id = 'email-test-cc0576d4-714b-42a3-88de-fc1bdefc3617'

const LogIn = () => {
	const session = useStytchSession()

	if (!(session === null)) {
		if (new Date(session.expires_at) > new Date()) window.location = '/logged-in'
	} 

	const stytchClient = useStytch();

	const [email, setEmail] = useState('');
	const [OTP, setOTP] = useState('')
	const [methodID, setMethodID] = useState('')

	const [sent, setSent] = useState(false);

	const login_text = <h2 className="columns-row__title">Please use your <br/>email to log in</h2>
	const validate_text = <h2 className="columns-row__title">We sent you an email with your log in Passcode</h2>
	const offline_text = <h2 className="columns-row__title">Kryp.to will be back soon!</h2>

	const login_field = <input type="text" placeholder="your@email.com" onChange={handleChange} />

	const otp_field = <input type="text" placeholder="123456" onChange={handleOTP} />

	const otp_submit = <button className="btn btn--lg" type="submit" onClick={login} >Login</button>

	const sendEmailMagicLink = () => {
		if(isValidEmail) {
			stytchClient.otps.email.loginOrCreate(email, {
				login_expiration_minutes: 60,
				signup_expiration_minutes: 60,
			}).then((res) => {
				console.log(res)
				setMethodID(res.method_id)
			});
			setSent(true)
		}
	};

	const login_submit = <button className="btn btn--lg" type="submit" onClick={sendEmailMagicLink} >Submit</button>

	function isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	}

	function handleChange(e) {
		setEmail(e.target.value)
	}

	function handleOTP(e) {
		setOTP(e.target.value)
	}

	function login(e) {
		stytchClient.otps.authenticate(
			OTP,
			methodID,
			{ session_duration_minutes: 60 }
		).then(resp => {
			console.log(resp)
		})
		.catch(err => {
			console.log(err)
		});
	}

	//Popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    // Open the popup automatically when the component mounts
    setIsPopupOpen(true);
  }, []); // Empty dependency array means this effect runs once on page load

  const closePopup = () => {
    // Close the popup when the close button is clicked
    setIsPopupOpen(false);
  };

	useLayoutEffect(() => {
        document.body.classList.add("login-pg-style");

		return () => {
			document.body.classList.remove('login-pg-style');
		};
    }, []);

	return (
		<>
			<Popup isOpen={isPopupOpen} onClose={closePopup}>
        <h2>
          Stay tuned
        </h2>
        <p>
          We're relaunching with two new 
          Contribution experiences, that will increase 
          funds raised for Charities and provide more 
          reward oppportunities for Contributors.
          <br/><br/>
          It's a win-win for everyone!
          <br/><br/>
          Cheers,
          <br/>
          <img src="./images/heading.svg" className="krypto-01" alt="krypto" />
        </p>
      </Popup>

			<div className="login-page">
				<BoxBg imageUrl="about-image-heading-bg.png" title="Log In" className="box-bg--about" />

				<ColumnsRow
					className="columns-row--type10 mobile-row-rev"
					content1={
						<>
						
						<BoxText>
							<div className="columns-row__text login-form">
								<p>
									<img src="./images/mascot.svg" alt="mascot" className="mascot page-mascot mascot-relative" />
								</p>
								{sent ? validate_text : login_text}
								{sent ? <></> : login_field}
								{sent ? otp_field : <></>}
								{sent ? otp_submit : login_submit}
								
							</div>
						</BoxText>
						
						</>
					}
					imageUrl="about-decor-bg.svg"
				/>
			</div>
			
		</>
	)
}


export default LogIn;
import React from "react";
import { Link } from "react-router-dom";

import SocialIcons from "../SocialIcons";

import "./style.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__box">
          <div className="footer__nav">
            <div className="footer__nav-item">
              <Link to="/about" className="footer__nav-link">
                About us
              </Link>
            </div>
            <div className="footer__nav-item m-border">
              <Link to="/how-it-works" className="footer__nav-link">
                How it works
              </Link>
            </div>
            <div className="footer__nav-item">
              <Link to="/six" className="footer__nav-link">
                <img src="./images/krypto6.svg" className="krypto6home-link" alt="what is krypto 6" />
              </Link>
            </div>
            <div className="footer__nav-item m-border">
              <Link to="/logged-in" className="footer__nav-link">
                Contribute
              </Link>
            </div>
            <div className="footer__nav-item">
              <Link to="/proceeds" className="footer__nav-link">
                Proof of Proceeds
              </Link>
            </div>
            <div className="footer__nav-item m-border">
              <Link to="/winners" className="footer__nav-link">
                Winners
              </Link>
            </div>
            <div className="footer__nav-item">
              <Link to="/merch" className="footer__nav-link">
                Merch
              </Link>
            </div>
            <div className="footer__nav-item m-border">
              <Link to="/charity-nomination" className="footer__nav-link">
                Nominate a charity
              </Link>
            </div>
            <div className="footer__nav-item">
              {false ? <Link to="/logged-in" className="footer__nav-link">
                play <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> for free
              </Link>: <Link to="/news" className="footer__nav-link">
                News
              </Link>}
            </div>
            <div className="footer__nav-item m-border">
              <Link to="/partners" className="footer__nav-link">
                Brand Partners
              </Link>
            </div>
            <div className="footer__nav-item">
              <Link to="/ventures" className="footer__nav-link">
                <img src="./images/heading.svg" className="krypto6home-link-header" alt="krypto ventures" /> VENTURES
              </Link>
            </div>
            <div className="footer__nav-item m-border">
              <Link to="/pledge" className="footer__nav-link">
                <img src="./images/heading.svg" className="krypto6home-link-header" alt="krypto pledge" /> PLEDGE
              </Link>
            </div>
          </div>
          <div className="footer__inner">
            <div className="footer__inner-top"></div>
            <div className="footer__inner-logo">
              <Link to="/">
                <img
                  src="./images/logo-white.svg"
                  alt="krypto"
                  className="img footer-logo"
                />
                <img
                  src="./images/mascot.svg"
                  alt="mascot"
                  className="mascot"
                />
              </Link>
            </div>
            <div className="footer__inner-bottom hide-desktop">
              <div className="footer__inner-col-wide">
                <Link
                  to="/contact"
                  className="footer__nav-link wm-50 hide-desktop white-font"
                >
                  Contact us
                </Link>
              </div>
            </div>
            <div className="footer__inner-bottom">
              <div className="footer__inner-col">
                <Link to="/contact" className="footer__nav-link hide-mobile">
                  Contact us
                </Link>
              </div>
              <div className="footer__inner-social">
                <SocialIcons />
              </div>
            </div>
          </div>
        </div>
        <div className="footer__foot">
          <div className="footer__copy">
            Copyright Ⓒ {new Date().getFullYear()} Digital Universe Technologies
            Inc. All rights reserved.
          </div>
          <div className="footer__policy">
            <div className="footer__policy-col">
              <Link to="/faq" className="footer__policy-link">
                FAQ
              </Link>
            </div>
            <div className="footer__policy-col">
              <Link to="/privacy-policy" className="footer__policy-link">
                Privacy Policy
              </Link>
            </div>
            <div className="footer__policy-col">
              <Link to="/terms" className="footer__policy-link">
                Terms
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import './style.scss';

const Dashboard = () => {

	return (
		<>
		
			<div id="krypto-dash" className="dashboard-styles">
				<div className="container">
					<div className="row row-01">
						<div className="col-md-4">
							<div className="block">
								<p className="block-title">
									Total Krypto
								</p>
								<p>
									BTC: 0.00000000
								</p>
								<p>
									ETH: 0.00000000
								</p>
								<p>
									USDC: 0.00000000
								</p>
								<p>
									USDT: 0.00000000
								</p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="block">
								<p className="block-title">
									Total Fiat
									<div className='currency-selector'>
										<h4>
											Currency:
										</h4>
										<select id="currencyList">
											<option value="USD" selected="selected">USD</option>
											<option value="EUR">EUR</option>
											<option value="JPY">JPY</option>
											<option value="GBP">GBP</option>
											<option disabled>──────────</option>
											<option value="AED">AED</option>
											<option value="AFN">AFN</option>
											<option value="ALL">ALL</option>
											<option value="AMD">AMD</option>
											<option value="ANG">ANG</option>
											<option value="AOA">AOA</option>
											<option value="ARS">ARS</option>
											<option value="AUD">AUD</option>
											<option value="AWG">AWG</option>
											<option value="AZN">AZN</option>
											<option value="BAM">BAM</option>
											<option value="BBD">BBD</option>
											<option value="BDT">BDT</option>
											<option value="BGN">BGN</option>
											<option value="BHD">BHD</option>
											<option value="BIF">BIF</option>
											<option value="BMD">BMD</option>
											<option value="BND">BND</option>
											<option value="BOB">BOB</option>
											<option value="BRL">BRL</option>
											<option value="BSD">BSD</option>
											<option value="BTN">BTN</option>
											<option value="BWP">BWP</option>
											<option value="BYN">BYN</option>
											<option value="BZD">BZD</option>
											<option value="CAD">CAD</option>
											<option value="CDF">CDF</option>
											<option value="CHF">CHF</option>
											<option value="CLP">CLP</option>
											<option value="CNY">CNY</option>
											<option value="COP">COP</option>
											<option value="CRC">CRC</option>
											<option value="CUC">CUC</option>
											<option value="CUP">CUP</option>
											<option value="CVE">CVE</option>
											<option value="CZK">CZK</option>
											<option value="DJF">DJF</option>
											<option value="DKK">DKK</option>
											<option value="DOP">DOP</option>
											<option value="DZD">DZD</option>
											<option value="EGP">EGP</option>
											<option value="ERN">ERN</option>
											<option value="ETB">ETB</option>
											<option value="EUR">EUR</option>
											<option value="FJD">FJD</option>
											<option value="FKP">FKP</option>
											<option value="GBP">GBP</option>
											<option value="GEL">GEL</option>
											<option value="GGP">GGP</option>
											<option value="GHS">GHS</option>
											<option value="GIP">GIP</option>
											<option value="GMD">GMD</option>
											<option value="GNF">GNF</option>
											<option value="GTQ">GTQ</option>
											<option value="GYD">GYD</option>
											<option value="HKD">HKD</option>
											<option value="HNL">HNL</option>
											<option value="HRK">HRK</option>
											<option value="HTG">HTG</option>
											<option value="HUF">HUF</option>
											<option value="IDR">IDR</option>
											<option value="ILS">ILS</option>
											<option value="IMP">IMP</option>
											<option value="INR">INR</option>
											<option value="IQD">IQD</option>
											<option value="IRR">IRR</option>
											<option value="ISK">ISK</option>
											<option value="JEP">JEP</option>
											<option value="JMD">JMD</option>
											<option value="JOD">JOD</option>
											<option value="JPY">JPY</option>
											<option value="KES">KES</option>
											<option value="KGS">KGS</option>
											<option value="KHR">KHR</option>
											<option value="KID">KID</option>
											<option value="KMF">KMF</option>
											<option value="KPW">KPW</option>
											<option value="KRW">KRW</option>
											<option value="KWD">KWD</option>
											<option value="KYD">KYD</option>
											<option value="KZT">KZT</option>
											<option value="LAK">LAK</option>
											<option value="LBP">LBP</option>
											<option value="LKR">LKR</option>
											<option value="LRD">LRD</option>
											<option value="LSL">LSL</option>
											<option value="LYD">LYD</option>
											<option value="MAD">MAD</option>
											<option value="MDL">MDL</option>
											<option value="MGA">MGA</option>
											<option value="MKD">MKD</option>
											<option value="MMK">MMK</option>
											<option value="MNT">MNT</option>
											<option value="MOP">MOP</option>
											<option value="MRU">MRU</option>
											<option value="MUR">MUR</option>
											<option value="MVR">MVR</option>
											<option value="MWK">MWK</option>
											<option value="MXN">MXN</option>
											<option value="MYR">MYR</option>
											<option value="MZN">MZN</option>
											<option value="NAD">NAD</option>
											<option value="NGN">NGN</option>
											<option value="NIO">NIO</option>
											<option value="NOK">NOK</option>
											<option value="NPR">NPR</option>
											<option value="NZD">NZD</option>
											<option value="OMR">OMR</option>
											<option value="PAB">PAB</option>
											<option value="PEN">PEN</option>
											<option value="PGK">PGK</option>
											<option value="PHP">PHP</option>
											<option value="PKR">PKR</option>
											<option value="PLN">PLN</option>
											<option value="PRB">PRB</option>
											<option value="PYG">PYG</option>
											<option value="QAR">QAR</option>
											<option value="RON">RON</option>
											<option value="RSD">RSD</option>
											<option value="RUB">RUB</option>
											<option value="RWF">RWF</option>
											<option value="SAR">SAR</option>
											<option value="SEK">SEK</option>
											<option value="SGD">SGD</option>
											<option value="SHP">SHP</option>
											<option value="SLL">SLL</option>
											<option value="SLS">SLS</option>
											<option value="SOS">SOS</option>
											<option value="SRD">SRD</option>
											<option value="SSP">SSP</option>
											<option value="STN">STN</option>
											<option value="SYP">SYP</option>
											<option value="SZL">SZL</option>
											<option value="THB">THB</option>
											<option value="TJS">TJS</option>
											<option value="TMT">TMT</option>
											<option value="TND">TND</option>
											<option value="TOP">TOP</option>
											<option value="TRY">TRY</option>
											<option value="TTD">TTD</option>
											<option value="TVD">TVD</option>
											<option value="TWD">TWD</option>
											<option value="TZS">TZS</option>
											<option value="UAH">UAH</option>
											<option value="UGX">UGX</option>
											<option value="USD">USD</option>
											<option value="UYU">UYU</option>
											<option value="UZS">UZS</option>
											<option value="VES">VES</option>
											<option value="VND">VND</option>
											<option value="VUV">VUV</option>
											<option value="WST">WST</option>
											<option value="XAF">XAF</option>
											<option value="XCD">XCD</option>
											<option value="XOF">XOF</option>
											<option value="XPF">XPF</option>
											<option value="ZAR">ZAR</option>
											<option value="ZMW">ZMW</option>
											<option value="ZWB">ZWB</option>
										</select>
									</div>
								</p>
								<p className="fiat-amount">
									0.00USD
								</p>
							</div>
						</div>
						<div className="col-md-4 valign">
							<div className="links">
								<a href="#"><span><i className="bi bi-arrow-right-circle"></i></span>Request Payout</a>
								<br/><br/><br/>
								<a href="#"><span><i className="bi bi-arrow-right-circle"></i></span>Create Wallet</a>
							</div>
						</div>
					</div>
				</div>

				<div className="tab-table">
					<div className="container">
						<ul className="nav nav-tabs" id="myTab" role="tablist">
							<li className="nav-item" role="presentation">
								<button className="nav-link active" id="lwa-tab" data-bs-toggle="tab" data-bs-target="#lwa" type="button" role="tab" aria-controls="lwa" aria-selected="true">
									Latest Wallet Addresses
								</button>
							</li>
							{/* <li class="nav-item" role="presentation">
								<button className="nav-link" id="invoices-tab" data-bs-toggle="tab" data-bs-target="#invoices" type="button" role="tab" aria-controls="invoices" aria-selected="false">
									Invoices
								</button>
							</li> */}
						</ul>
						<div className="tab-content" id="myTabContent">
							<div className="tab-pane fade show active" id="lwa" role="tabpanel" aria-labelledby="lwa-tab">
								<div className="row">
									<div className="col-md-12 text-end move-right">
										<div className="flex-inline">
											<p>
												Currency: 
											</p>
											<select id="cryptoCurrencyList">
												<option value="BTC" selected="selected">BTC</option>
												<option value="ETH">ETH</option>
												<option value="USDC">USDC</option>
												<option value="USDT">USDT</option>
											</select>
										</div>
									</div>
								</div>
								<div className="overview-block">
									<div className="row">
										<div className="col-md-3 valign">
											<h3>
												Overview
											</h3>
										</div>
										<div className="col-md-3 col-4 center-mobile">
											<span>
												1
											</span>
											<p>
												Wallets Created
											</p>
										</div>
										<div className="col-md-3 col-4 center-mobile">
											<span>
												0
											</span>
											<p>
												Total Collected
											</p>
										</div>
										<div className="col-md-3 col-4 center-mobile">
											<span>
												0
											</span>
											<p>
												Total Sold
											</p>
										</div>
									</div>
								</div>
								<div className="wallet-block">
									<div className="row">
										<div className='col-md-5 col-10'>
											<div className='row mobile-pb'>
												<div className="col-md-3 col-2">
													<div className="circle-icon">
														<i className="bi bi-wallet"></i>
													</div>
												</div>
												<div className="col-md-9 col-8 p-0 mobile-pl">
													<p className='wallet-name'>
														Test 001
													</p>
													<p className='wallet-token'>
														128sfgeaergearbhrebh43784gut2
														<a href="#" className='copylink'><i class="bi bi-link"></i></a>
													</p>
												</div>
											</div>
										</div>
										<div className='col-md-2 col-2 text-center p-0'>
											<span className='label'>
												Not Autosell
											</span>
										</div>
										<div className='col-md-5 wallet-details p-0'>
											<div className='row text-center g-0'>
												<div className='col-md-4 col-5'>
													<p className='amount'>
														BTC 
														<span>
															0.00000000
														</span>
													</p>
												</div>
												<div className='col-md-4 col-5'>
													<p className='date'>
														06-01-2022
													</p>
												</div>
												<div className='col-md-4 col-2'>
													<a href="#" className='more-link'>
														<i className="bi bi-chevron-right"></i>
														<br/>
														More Info
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="wallet-block">
									<div className="row">
										<div className='col-md-5 col-10'>
											<div className='row mobile-pb'>
												<div className="col-md-3 col-2">
													<div className="circle-icon">
														<i className="bi bi-wallet"></i>
													</div>
												</div>
												<div className="col-md-9 col-8 p-0 mobile-pl">
													<p className='wallet-name'>
														Test 001
													</p>
													<p className='wallet-token'>
														128sfgeaergearbhrebh43784gut2
														<a href="#" className='copylink'><i class="bi bi-link"></i></a>
													</p>
												</div>
											</div>
										</div>
										<div className='col-md-2 col-2 text-center p-0'>
											<span className='label'>
												Not Autosell
											</span>
										</div>
										<div className='col-md-5 wallet-details p-0'>
											<div className='row text-center g-0'>
												<div className='col-md-4 col-5'>
													<p className='amount'>
														BTC 
														<span>
															0.00000000
														</span>
													</p>
												</div>
												<div className='col-md-4 col-5'>
													<p className='date'>
														06-01-2022
													</p>
												</div>
												<div className='col-md-4 col-2'>
													<a href="#" className='more-link'>
														<i className="bi bi-chevron-right"></i>
														<br/>
														More Info
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="tab-pane fade" id="invoices" role="tabpanel" aria-labelledby="invoices-tab">
								invoices info
							</div> */}
						</div>
					</div>
				</div>
			</div>
			
		</>
	)
}

export default Dashboard;

import React from "react";
import { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

import Carousel from "react-bootstrap/Carousel";

import ArrowLink from "../../components/ArrowLink";
import ColumnsRow from "../../components/ColumnsRow";
import Select from "../../components/Select";
import DropDownSelect from "../../components/DropDownSelect";
import BoxBg from "../../components/BoxBg";
import PartnersSlider from "../../components/PartnersSlider";
import BoxText from '../../components/BoxText';
import Popup from "../../components/Popup/Popup";

import border from "../../../src/assets/thickest.gif";
import borderMobile from "../../../src/assets/thickest-mobile.gif";
import logo from "../../../src/assets/heading.svg";
import six from "../../../src/assets/six.svg";
import via from "../../../src/assets/via.svg";
import love from "../../../src/assets/love.jpeg";
import transparent from "../../../src/assets/transparent.png"


import "./style.scss";

import axios from 'axios';

const currency = [
  { id: 1, value: 'usd_total', text: 'USD' },
  { id: 2, value: 'sol_total', text: 'SOL' },
  { id: 5, value: 'eth_total', text: 'ETH' }
];

const totals = [
  { id: 1, value: 'total', text: 'Total' },
  { id: 2, value: 'this', text: 'Current' },
];

const upcomingOptions = [
  {
    id: 1,
    value: "one",
    text: "TBA",
  },
];

const Home = () => {
  const [_currency, setCurrency] = useState('usd_total')
  const [charityCurrency, setCharityCurrency] = useState('usd_total')
  const [period, setPeriod] = useState('total')
  const [charityPeriod, setCharityPeriod] = useState('total')

  const [polled, setPolled] = useState(false)
  const [compound, setCompound] = useState({
    total: {
      sol_total: 0,
      eth_total: 0,
      usd_total: 0
    },
    this: {
      sol_total: 0,
      eth_total: 0,
      usd_total: 0
    }
  })

  if(!polled) {
    setPolled(true)
    axios.post('https://smartpaywebhook.justusmoeller2.repl.co/get_compound', {}).then(res => {
			console.log(res.data)
      setCompound(res.data)
	})
  }
  
  const [odometerValueK, setOdometerValueK] = useState(0);
  const [odometerValueR, setOdometerValueR] = useState(0);
  const [odometerValueY, setOdometerValueY] = useState(0);
  const [odometerValueP, setOdometerValueP] = useState(0);
  const [odometerValueT, setOdometerValueT] = useState(0);
  const [odometerValueO, setOdometerValueO] = useState(0);

  const changeCurrency = (currencyValue) => {
    setCurrency(currencyValue);
  };

  const changePeriod = (changePeriod) => {
    setPeriod(changePeriod);
  };

  const changeCharityCurrency = (changeCharityCurrency) => {
    setCharityCurrency(changeCharityCurrency);
  };

  const changeCharityPeriod = (changeCharityPeriod) => {
    setCharityPeriod(changeCharityPeriod);
  };


  //Popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    // Open the popup automatically when the component mounts
    setIsPopupOpen(true);
  }, []); // Empty dependency array means this effect runs once on page load

  const closePopup = () => {
    // Close the popup when the close button is clicked
    setIsPopupOpen(false);
  };


  // Slot numbers
  useEffect(() => {
    document.body.classList.add("home-page");

    setTimeout(() => {
      setOdometerValueK(9);
    }, 3000);

    setTimeout(() => {
      setOdometerValueR(11);
    }, 4000);

    setTimeout(() => {
      setOdometerValueY(14);
    }, 5000);

    setTimeout(() => {
      setOdometerValueP(23);
    }, 6000);

    setTimeout(() => {
      setOdometerValueT(35);
    }, 7000);

    setTimeout(() => {
      setOdometerValueO(36);
    }, 8000);

    return () => {
      document.body.classList.remove("home-page");
    };
  }, []);

  return (
    <>
      <Popup isOpen={isPopupOpen} onClose={closePopup}>
        <h2>
          Stay tuned
        </h2>
        <p>
          We're relaunching with two new 
          Contribution experiences, that will increase 
          funds raised for Charities and provide more 
          reward oppportunities for Contributors.
          <br/><br/>
          It's a win-win for everyone!
          <br/><br/>
          Cheers,
          <br/>
          <img src="./images/heading.svg" className="krypto-01" alt="krypto" />
        </p>
      </Popup>

      <div className="header-top">
        <div>
          <img src="./images/krypto-elephant.png" className="top-logo" alt="top logo" />
          <p>
            Pioneering Contribute to Earn
          </p>
        </div>
      </div>

      <div className="logo-box show-home">
        <div className="border-imgs">
          <div className="desktop-only">
            <img src={border} className="border-img" alt="border" />
          </div>
          <div className="mobile-only">
            <img src={borderMobile} className="border-img" alt="border" />
          </div>
        </div>
        <ColumnsRow
          className="columns-row--type12 section-slot-machine no-border"
          content1={
            <>
              <div className="slot-machine-active">
                <div className="slot-logo">
                  <img src="./images/heading.svg" alt="kryp.to" class="img" />
                </div>
                <div className="slot-machine-header">
                  <p className="slot-header">Next draw: TBA {false ? <a href="logged-in" className="bold-text" style={{ margin: '0px 5px'}}>Contribute Now!</a> : <div /> } </p> 
                </div>
                <div className="slot-machine-container">
                  <div className="slot-machine">
                    <div className="slot-number slot-01">
                      <div className="slot-value counter">
                        <span className="letter letter-k">k</span>
                        <span className="odometer-k">
                          <Odometer
                            value={odometerValueK}
                            format="(,ddd)"
                            theme="default"
                            duration={10000}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="slot-number slot-02">
                      <div className="slot-value counter">
                        <span className="letter letter-r">r</span>
                        <span className="odometer-r">
                          <Odometer
                            value={odometerValueR}
                            format="(,ddd)"
                            theme="default"
                            duration={20000}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="slot-number slot-03">
                      <div className="slot-value counter">
                        <span className="letter letter-y">y</span>
                        <span className="odometer-y">
                          <Odometer
                            value={odometerValueY}
                            format="(,ddd)"
                            theme="default"
                            duration={30000}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="slot-number slot-04">
                      <div className="slot-value counter">
                        <span className="letter letter-p">p</span>
                        <span className="odometer-p">
                          <Odometer
                            value={odometerValueP}
                            format="(,ddd)"
                            theme="default"
                            duration={40000}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="slot-number slot-05">
                      <div className="slot-value counter">
                        <span className="letter letter-t">.t</span>
                        <span className="odometer-t">
                          <Odometer
                            value={odometerValueT}
                            format="(,ddd)"
                            theme="default"
                            duration={50000}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="slot-number slot-06">
                      <div className="slot-value counter">
                        <span className="letter letter-o">o</span>
                        <span className="odometer-o">
                          <Odometer
                            value={odometerValueO}
                            format="(,ddd)"
                            theme="default"
                            duration={60000}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p className='slot-winners'>
                  <a href="winners" className="bold-text">View Winners</a>
                </p>
              </div>
            </>
          }
        />
      </div>

      {/* <BoxText className="m-02 carousel-section">
        <div>
          <Carousel
            style={{ position: "relative" }}
            fade={true}
            interval={3000}
          >
            <Carousel.Item>
              <a href="six">
                <img src={six} alt="six" className="six" />
                <Carousel.Caption>
                  <h2>
                    Disruptive <br />
                    Global Lottery
                  </h2>
                </Carousel.Caption>
              </a>
            </Carousel.Item>
            <Carousel.Item className="draw-slide">
              <img src={transparent} alt="draw" className="draw" />
              <Carousel.Caption>
                <h2>
                  <span>The next kryp.to SIX draw date</span>
                  March 16th 2023 <br className='mobile-only'/>at 6pm PST
                </h2>
                <a href="login" class="bold-text">
                  Contribute now!
                </a>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="love-slide">
              <img src={love} alt="love" className="six" />
              <Carousel.Caption>
                <h2>
                  LOVE <br />
                  <span>by Thomas Hussung</span>
                </h2>
                <a href="love" class="bold-text">
                  Learn more
                </a>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <a href="via">
                <img src={via} alt="via" className="via" />
                <Carousel.Caption>
                  <h2>
                    Commerce <br />
                    with Purpose
                  </h2>
                </Carousel.Caption>
              </a>
            </Carousel.Item>
          </Carousel>
        </div>
			</BoxText> */}

      <ColumnsRow
        className="columns-row--type0"
        content1={
          <>
            <div className="columns-row__text">
              <h2 className="columns-row__title columns-row__title--bold mobile-text-header">
                Play with PURPOSE
              </h2>
              <p className="pwp">
                With every $5 USD contribution to support our global network of charities, you'll receive one complimentary ticket for the <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" />
              </p>
              <ArrowLink
                to="/logged-in"
                text="Contribute Now"
              />
            </div>
          </>
        }
        content2={
          <>
            <h2 className="columns-row__title columns-row__title--bold mobile-text-header">
              What is the <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> ?
            </h2>
            <p>
              We are a global rewards lottery, there's a Grand Prize Winner every draw! 
              <br/><br/>
              50% of the funds contributed goes to charities in real-time, 40% goes to the global rewards pool, 10% goes to <img src="./images/heading.svg" className="krypto6home krypto-img-sm" alt="what is krypto 6" /> it keeps our lights on and we reinvest back into the Web3 community.
              <br/><br/>
            </p>
            <ArrowLink
              to="/logged-in"
              text={
                <div>
                  Philanthropy that is <strong>INCLUSIVE</strong> and <strong>ASPIRATIONAL</strong>... Play the
                  <img src="./images/six.svg" className="the-six" alt="the 6" />
                </div>
              }
            />
            <div className='btn-space'>
              <a href="how-to-contribute" className="bold-text">How to Contribute at <img src="./images/heading.svg" className="krypto6home krypto-img-sm" alt="what is krypto 6" /></a>
            </div>
          </>
        }
      />

      <div className="six-jackpot">
        <div className="container">
          <div className="six-jackpot__outer">
            <div className="six-jackpot__title"><img src="./images/krypto6.svg" alt="kryp.to SIX" class="k6-img"/></div>
            <div className="six-jackpot__box">
              <div className="six-jackpot__label">Real-time <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> rewards</div>
              <div className="six-jackpot__wrap jackpot-selects">
                <div className="six-jackpot__value">{_currency == 'usd_total' ? '$ ' + (compound[period][_currency]*.4).toFixed(2) : (compound[period][_currency]*.4).toFixed(4)}</div>
                <div className="six-jackpot__select mr-5">
                  <DropDownSelect options={currency} onChange={changeCurrency} />
                </div>
                <div className="six-jackpot__select ml-5">
                  <DropDownSelect options={totals} onChange={changePeriod} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="/six" className="row-link">
        <ColumnsRow
          className="columns-row--type01"
          content1={
            <>
              <h2 className="columns-row__title">Quick <img src="./images/six.svg" className="the-six six-bigger" alt="the 6" /></h2>
              <div className="columns-row__text">
                <p>
                  With every $5 USD contribution in digital currency,
                  you will receive a complimentary set of 6 randomly generated
                  numbers.
                </p>
                <p className="bold-font">
                  Grand Prize Winners will be randomly selected every draw!
                </p>
              </div>
              <div className="columns-row__box">
                <ArrowLink to="/six" text="Contribute Now" />
              </div>
            </>
          }
          imageUrl="six.svg"
        />
      </a>

      {/* <div className="six-jackpot six-jackpot--reverse">
        <div className="container">
          <div className="six-jackpot__outer">
            <div className="six-jackpot__title">kryp.to VIA</div>
            <div className="six-jackpot__box d-flex">
              <h2 className="six-jackpot__label via-text">
                Upcoming VIA experiences
              </h2>
              <div className="six-jackpot__wrap">
                <div className="six-jackpot__select">
                  <DropDownSelect options={upcomingOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <a href="/via" className="row-link">
        <ColumnsRow
          className="columns-row--type02"
          content1={
            <>
              <h2 className="columns-row__title">Very Important Avatar</h2>
              <div className="columns-row__text">
                <p>
                  VIA is your access to the real-world and beyond. By making a
                  contribution to support charitable organizations and social
                  causes, you will get complimentary access to concerts, sports,
                  special events, exclusive parties, products and MORE in the
                  virtual or real-world.
                </p>
              </div>
              <div className="columns-row__box">
                <ArrowLink to="/via" text="Contribute Now, Get Access" />
              </div>
            </>
          }
          imageUrl="via.svg"
        />
      </a> */}

      <BoxBg imageUrl="home-image-bg.png" />

      <div className="six-jackpot six-jackpot--cols">
        <div className="container">
          <div className="six-jackpot__outer">
            <div className="six-jackpot__info">
              Real time contributions have been made to:
            </div>
            <div className="six-jackpot__box">
              <div className="six-jackpot__label">Contributions to charities</div>
              <div className="six-jackpot__wrap jackpot-selects">
                <div className="six-jackpot__value">{charityCurrency == 'usd_total' ? '$ ' + (compound[charityPeriod][charityCurrency]*.5).toFixed(2) : (compound[charityPeriod][charityCurrency]*.5).toFixed(4)}</div>
                <div className="six-jackpot__select mr-5">
                  <DropDownSelect options={currency} onChange={changeCharityCurrency} />
                </div>
                <div className="six-jackpot__select ml-5">
                  <DropDownSelect options={totals} onChange={changeCharityPeriod} />
                </div>
              </div>
            </div>
            <PartnersSlider />
          </div>
        </div>
      </div>

      <ColumnsRow
        className="columns-row--type03"
        content1={
          <>
            <h2 className="columns-row__title bold-text">Change in action</h2>
            <div className="columns-row__text">
              <p>
              <img src="./images/heading.svg" className="krypto6home krypto-img-sm ml-0" alt="what is krypto 6" /> provides a decentralized platform where together, we can
                make real-time, ethical contributions to charities around the
                world.
              </p>
            </div>
          </>
        }
        content2={
          <>
            <div className="mobile-10 btn-width">
              <ArrowLink
                to="/charity-nomination"
                text="Did We Miss Anyone? Nominate a Charity!"
              />
              <div className="no-arrow custom-333">
                <p>
                  Featuring new charities every <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> draw
                </p>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Home;

import React, { useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";

import BoxBg from '../../components/BoxBg';
import BoxText from '../../components/BoxText';
import ColumnsRow from '../../components/ColumnsRow';


const ComKids = () => {

  // useLayoutEffect(() => {
  //   document.body.classList.add("charity-pg-style");

  //   return () => {
  //   document.body.classList.remove('charity-pg-style');
  //   };
  // }, []);

	const navigate = useNavigate();

  const reroute = () => {
    navigate("/logged-in");
  };

	return (
		<>

      <BoxText className="m-02 fundraiser-heading">
        <img src="./images/charities/comkids.png" alt="comkids" className="charity-logo mobile-center" />
        <div className="columns-row__text">
          <h2 class="charity-text mobile-center">
            Support ComKids by making a contribution!
          </h2>
        </div>
        <div className="columns-row__box mobile-center">
          <button
            className="btn btn--lg main-btn"
            type="submit"
            onClick={reroute}
          >
            Contribute Now
          </button>
        </div>
			</BoxText>

      <BoxBg imageUrl="charity-nomination-image-heading-bg.png" title="Prize Winners" className="box-bg--charity-nomination" style={{display: 'block !important'}} />

      <ColumnsRow
        className="columns-row--type13 section-50"
        content1={
          <>
            <div className="winners-content misc-prize">
              <h2>
                Signed Auston Matthews Jersey
              </h2>
              <h3>
                Winning Numbers
              </h3>
              <div className='winning-numbers'>
                <span>1</span>
                <span>11</span>
                <span>17</span>
                <span>18</span>
                <span>43</span>
                <span>48</span>
              </div>
              <h3>
                Winner
              </h3>
              <div className="tickets-section winners-section">
                <div className="ticket winner-list">
                  <div className="ticket-header">
                    <p>
                      <span>Wallet Address</span>
                    </p>
                  </div>
                  <div className="winners">
                    <div className="winner">
                      <p className="w-address">0x21...a0e2</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        content2={
          <>
            <div className="winners-content misc-prize">
              <h2>
                Four Seasons Toronto Experience
              </h2>
              <h3>
                Winning Numbers
              </h3>
              <div className='winning-numbers'>
                <span>15</span>
                <span>21</span>
                <span>26</span>
                <span>27</span>
                <span>29</span>
                <span>43</span>
              </div>
              <h3>
                Winner
              </h3>
              <div className="tickets-section winners-section">
                <div className="ticket winner-list">
                  <div className="ticket-header">
                    <p>
                      <span>Wallet Address</span>
                    </p>
                  </div>
                  <div className="winners">
                    <div className="winner">
                      <p className="w-address">0x80...e47c</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
			/>

      <BoxText className="m-02 previous-results rewards-tab-table">
				<div className="columns-row__text">
					<div className="winners-content">
						<h1>
							{/* <img src="./images/krypto6.png" alt="krypto 6" className="krypto6" /> <br className='mobile-only'/> */} Rewards
						</h1>
						<div className="results-accordion">

              <div className="tabs rewards-tabs">

                <div className="tab">
                  <input type="checkbox" id="chck2"/>
                  <label className="tab-label" for="chck2">
                    <h3>
                      For making a contribution at kryp.to/ComKids
                    </h3>
                  </label>
                  <div className="tab-content">
                    <div className='rewards-pool'>
                      <p>
                        Two randomly selected contributors will receive:
                        <br/><br/>
                        • Signed Auston Matthews Jersey
                        <br/>
                        • Four Seasons Toronto: One night stay and spa package   
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tab">
                  <input type="checkbox" id="chck1"/>
                  <label className="tab-label" for="chck1">
                    <h3>
                      For ComKids users entering their email at kryp.to/ComKids at the event
                    </h3>
                  </label>
                  <div className="tab-content">
                    <div className='rewards-pool'>
                      <p>
                        One randomly selected contributor will receive:
                        <br/><br/>
                        • $500 contribution from kryp.to in the winners name for being randomly selected as the email winner. 
                        <br/><br/>
                        Even if you don't have a digital wallet and digital currencies 'Ethereum or Solana' you can still enter your email to win and support ComKids.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tab">
                  <input type="checkbox" id="chck3"/>
                  <label className="tab-label" for="chck3">
                    <h3>
                      For ComKids users to follow us on Instagram
                    </h3>
                  </label>
                  <div className="tab-content">
                    <div className='rewards-pool'>
                      <p>
                        One randomly selected contributor will receive:
                        <br/><br/>
                        • $500 contribution reward to one lucky winner, who follow us on IG and sends us a DM with their email and #ComKids. 
                      </p>
                    </div>
                  </div>
                </div>

              </div>

						</div>

            <div className="content-rewards">
              <h2>
                Contributions: ComKids 19th Annual
                <br className='desktop-only'/> Celebrity Sports Mixer at the Hockey Hall of Fame 
              </h2>
              <p>
                <b>Starts:</b> Wednesday April 26th 6am EST 
                <br/>
                <b>Ends:</b> Monday June 10th 6pm EST
              </p>
            </div>
					</div>
				</div>
			</BoxText>

      <BoxText className="m-02 previous-results kryptophant-10">
        <img src="./images/mascot.svg" alt="comkids" className="charity-logo mobile-center" />
			</BoxText>
		</>
	)
}

export default ComKids;

import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import BoxBg from '../../components/BoxBg';
import ColumnsRow from '../../components/ColumnsRow';

import './style.scss';

import {
    WalletModalProvider,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';


const Pledge = () => {

	const navigate = useNavigate();

	const reroute = () => {
        navigate('/logged-in')
    }

	// useEffect(() => {
	// 	document.querySelector('#btn-pledge .wallet-adapter-button').innerHTML = `
	// 		<img src="./images/pledge.png" alt="contribute by krypto"/>
	// 	`;
	// }, []);

	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="Pledge" className="box-bg--how-it-works" />

			<ColumnsRow
				className="columns-row--type09 kryptophant"
				content1={
					<>
						<div className="columns-row__text">
							<h2 className="columns-row__title">
								Contribute with PURPOSE
							</h2>
							<p>
								It's your choice if you would like to participate in our experiences or simply donate to charities seamlessly.
								<br/><br/>
								You can <b>decline to claim you ticket</b> and we'll handle your contributions for you, thanks to your generosity!
							</p>
						</div>
						<div className="columns-row__box">
							<button className="btn btn--lg main-btn" type="submit" onClick={reroute}>Contribute Now</button>
						</div>
					</>
				}
				imageUrl="mascot.svg"
			/>

			<ColumnsRow
				className="columns-row--type10"
				content1={
					<>
						<div className="columns-row__text">
							<h2 className="columns-row__title">
								More ways to PLEDGE
							</h2>
							<p className="mb-45">
								The <img src="./images/heading.svg" className="krypto6home krypto-img-sm" alt="what is krypto 6" /> PLEDGE provides our community winners a real-time option to contribute all or part of their rewards to our beneficiaries list and/or a charity of their choice.
							</p>
						</div>
					</>
				}
				imageUrl="six-image-bg.svg"
			/>

		</>
	)
}

export default Pledge;

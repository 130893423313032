import React, { useLayoutEffect } from 'react';

import BoxBg from '../../components/BoxBg';
// import ColumnsRow from '../../components/ColumnsRow';
import BoxText from '../../components/BoxText';

import './style.scss';

const PreviousResults = () => {

	useLayoutEffect(() => {
        document.body.classList.add("winners-page");

		return () => {
			document.body.classList.remove('winners-page');
		};
    }, []);

	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="Previous Results" className="box-bg--how-it-works" />

			<BoxText className="m-02 previous-results">
				<div className="columns-row__text">
					<div className="winners-content">
						<h1>
							<img src="./images/krypto6.png" alt="krypto 6" className="krypto6" /> <br className='mobile-only'/> Previous Results
						</h1>
						<div className="results-accordion">
							
						<div className="tabs">
								<div className="tab">
									<input type="checkbox" id="chck211111"/>
									<label className="tab-label" htmlFor="chck211111">
										<h3>September 9, 2023</h3>
										<div className="winning-numbers desktop-only">
											<span>9</span>
											<span>11</span>
											<span>14</span>
											<span>23</span>
											<span>35</span>
											<span>36</span>
										</div>
									</label>
									<div className="tab-content">
										<div className="mobile-only">
											<h2>Winning Numbers</h2>
											<div className="winning-numbers">
												<span>9</span>
												<span>11</span>
												<span>14</span>
												<span>23</span>
												<span>35</span>
												<span>36</span>
											</div>
										</div>
										<div className="rewards-pool">
											<p>Rewards Pool Value</p>
											<h3>$303.96</h3>
										</div>
										<div className="past-winner">
											<h3>Grand Prize Winner(s)</h3>
											<div className="winners">
												<div className="winner">
													<p className="w-address">0xe3...0d6a</p>
													<span className="w-amount">$303.96</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs">
								<div className="tab">
									<input type="checkbox" id="chck21111"/>
									<label className="tab-label" htmlFor="chck21111">
										<h3>August 5, 2023</h3>
										<div className="winning-numbers desktop-only">
											<span>7</span>
											<span>24</span>
											<span>25</span>
											<span>27</span>
											<span>33</span>
											<span>38</span>
										</div>
									</label>
									<div className="tab-content">
										<div className="mobile-only">
											<h2>Winning Numbers</h2>
											<div className="winning-numbers">
												<span>7</span>
												<span>24</span>
												<span>25</span>
												<span>27</span>
												<span>33</span>
												<span>38</span>
											</div>
										</div>
										<div className="rewards-pool">
											<p>Rewards Pool Value</p>
											<h3>$303.53</h3>
										</div>
										<div className="past-winner">
											<h3>Grand Prize Winner(s)</h3>
											<div className="winners">
												<div className="winner">
													<p className="w-address">9JE6...Gock</p>
													<span className="w-amount">$303.53</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs">
								<div className="tab">
									<input type="checkbox" id="chck2111"/>
									<label className="tab-label" for="chck2111">
										<h3>
											July 8, 2023
										</h3>
										<div className='winning-numbers desktop-only'>
											<span>2</span>
											<span>14</span>
											<span>19</span>
											<span>20</span>
											<span>28</span>
											<span>33</span>
										</div>
									</label>
									<div className="tab-content">
										<div className="mobile-only">
											<h2>
												Winning Numbers
											</h2>
											<div className='winning-numbers'>
												<span>2</span>
												<span>14</span>
												<span>19</span>
												<span>20</span>
												<span>28</span>
												<span>33</span>
											</div>
										</div>
										<div className='rewards-pool'>
											<p>
												Rewards Pool Value
											</p>
											<h3>
												$222.90
											</h3>
										</div>
										<div className="past-winner">
											<h3>
												Grand Prize Winner(s)
											</h3>
											<div class="winners">
												<div className='winner'>
													<p className='w-address'>
														0x23...d9aa
														<br/>
													</p>
													<span className='w-amount'>
														$222.90
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs">
								<div className="tab">
									<input type="checkbox" id="chck211"/>
									<label className="tab-label" for="chck211">
										<h3>
											June 10, 2023
										</h3>
										<div className='winning-numbers desktop-only'>
											<span>2</span>
											<span>4</span>
											<span>6</span>
											<span>12</span>
											<span>32</span>
											<span>38</span>
										</div>
									</label>
									<div className="tab-content">
										<div className="mobile-only">
											<h2>
												Winning Numbers
											</h2>
											<div className='winning-numbers'>
												<span>2</span>
												<span>4</span>
												<span>6</span>
												<span>12</span>
												<span>32</span>
												<span>38</span>
											</div>
										</div>
										<div className='rewards-pool'>
											<p>
												Rewards Pool Value
											</p>
											<h3>
												$200.93
											</h3>
										</div>
										<div className="past-winner">
											<h3>
												Grand Prize Winner(s)
											</h3>
											<div class="winners">
												<div className='winner'>
													<p className='w-address'>
														0x80...e47c
														<br/>
													</p>
													<span className='w-amount'>
														$200.93
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs">
								<div className="tab">
									<input type="checkbox" id="chck212"/>
									<label className="tab-label" for="chck212">
										<h3>
											May 11, 2023
										</h3>
										<div className='winning-numbers desktop-only'>
											<span>1</span>
											<span>7</span>
											<span>13</span>
											<span>17</span>
											<span>30</span>
											<span>49</span>
										</div>
									</label>
									<div className="tab-content">
										<div className="mobile-only">
											<h2>
												Winning Numbers
											</h2>
											<div className='winning-numbers'>
												<span>1</span>
												<span>7</span>
												<span>13</span>
												<span>17</span>
												<span>30</span>
												<span>49</span>
											</div>
										</div>
										<div className='rewards-pool'>
											<p>
												Rewards Pool Value
											</p>
											<h3>
												$831.93
											</h3>
										</div>
										<div className="past-winner">
											<h3>
												Grand Prize Winner(s)
											</h3>
											<div class="winners">
												<div className='winner'>
													<p className='w-address'>
														0xf168xxxxxxx3a8ad8
														<br/>
													</p>
													<span className='w-amount'>
														$831.93
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs">
								<div className="tab">
									<input type="checkbox" id="chck2"/>
									<label className="tab-label" for="chck2">
										<h3>
											April 13, 2023
										</h3>
										<div className='winning-numbers desktop-only'>
											<span>11</span>
											<span>14</span>
											<span>34</span>
											<span>35</span>
											<span>36</span>
											<span>41</span>
										</div>
									</label>
									<div className="tab-content">
										<div className="mobile-only">
											<h2>
												Winning Numbers
											</h2>
											<div className='winning-numbers'>
												<span>11</span>
												<span>14</span>
												<span>34</span>
												<span>35</span>
												<span>36</span>
												<span>41</span>
											</div>
										</div>
										<div className='rewards-pool'>
											<p>
												Rewards Pool Value
											</p>
											<h3>
												$850.16
											</h3>
										</div>
										<div className="past-winner">
											<h3>
												Grand Prize Winner(s)
											</h3>
											<div class="winners">
												<div className='winner'>
													<p className='w-address'>
														0x6f3exxxxxxx939881
														<br/>
													</p>
													<span className='w-amount'>
														$850.16
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs">
								<div className="tab">
									<input type="checkbox" id="chck25"/>
									<label className="tab-label" for="chck25">
										<h3>
											March 16, 2023
										</h3>
										<div className='winning-numbers desktop-only'>
											<span>5</span>
											<span>6</span>
											<span>20</span>
											<span>28</span>
											<span>40</span>
											<span>48</span>
										</div>
									</label>
									<div className="tab-content">
										<div className="mobile-only">
											<h2>
												Winning Numbers
											</h2>
											<div className='winning-numbers'>
												<span>5</span>
												<span>6</span>
												<span>20</span>
												<span>28</span>
												<span>40</span>
												<span>48</span>
											</div>
										</div>
										<div className='rewards-pool'>
											<p>
												Rewards Pool Value
											</p>
											<h3>
												$803.16
											</h3>
										</div>
										<div className="past-winner">
											<h3>
												Grand Prize Winner(s)
											</h3>
											<div class="winners">
												<div className='winner'>
													<p className='w-address'>
														0x99c2xxxxxxx711c31
														<br/>
													</p>
													<span className='w-amount'>
														$803.16
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs">
								<div className="tab">
									<input type="checkbox" id="chck1"/>
									<label className="tab-label" for="chck1">
										<h3>
											February 14, 2023
										</h3>
										<div className='winning-numbers desktop-only'>
											<span>9</span>
											<span>20</span>
											<span>21</span>
											<span>23</span>
											<span>26</span>
											<span>31</span>
										</div>
									</label>
									<div className="tab-content">
										<div className="mobile-only">
											<h2>
												Winning Numbers
											</h2>
											<div className='winning-numbers'>
												<span>9</span>
												<span>20</span>
												<span>21</span>
												<span>23</span>
												<span>26</span>
												<span>31</span>
											</div>
										</div>
										<div className='rewards-pool'>
											<p>
												Rewards Pool Value
											</p>
											<h3>
												$703.25
											</h3>
										</div>
										<div className="past-winner">
											<h3>
												Grand Prize Winner(s)
												<br/>
												Match: 6/6
											</h3>
											<div class="winners">
												<div className='winner'>
													<p className='w-address'>
														23NKoFKhLzKYWKD21hzFJMRezbon3hQxcsm5z2UYB4iu
														<br/>
													</p>
													<span className='w-amount'>
														$703.25
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</BoxText>

			<BoxText className="m-02 winners-section-03">
				<div className="columns-row__text">
					<p className="mb-45">
						This complimentary <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> ticket has one lifecycle.
					</p>
				</div>
			</BoxText>

		</>
	)
}

export default PreviousResults;

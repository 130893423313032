import React from 'react';
import { useNavigate } from "react-router-dom";

import ColumnsRow from '../../components/ColumnsRow';
import BoxText from '../../components/BoxText';
import BoxBg from '../../components/BoxBg';

import './style.scss';

import {
    WalletModalProvider,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

const Six = () => {

	const navigate = useNavigate();

	const reroute = () => {
        navigate('/logged-in')
    }

	return (
		<>
			<BoxBg imageUrl="six-image-heading-bg.png" title="Six" className="box-bg--six" />
		
			<BoxText>
				<p className="mobile-text-header">Play with PURPOSE</p>
			</BoxText>

			<ColumnsRow
				className="columns-row--type10"
				content1={
					<>
						<div className="columns-row__text">
							<p>
							With every $5 USD contribution in digital currency, you will receive a complimentary ticket to the <img src="./images/krypto6.svg" className="krypto6home-sm" alt="krypto 6" /> draw. 
							</p>
							<p className="bold-font">
								Grand Prize Winners will be randomly selected every draw!
							</p>
						</div>
						<div className="columns-row__box">
							<button className="btn btn--lg main-btn" type="submit" onClick={reroute}>Contribute Now</button>
						</div>
					</>
				}
				imageUrl="six-image-bg.svg"
			/>

			<BoxText>
				<p className="mobile-20">The <img src="./images/heading.svg" className="krypto6home krypto-img-sm mlr-5" alt="what is krypto 6" /> PLEDGE provides our community winners a real-time option to contribute all or part of their rewards to our beneficiaries list and/or a charity of their choice.</p>
			</BoxText>

			<div className="refer-friend">
                <div className="container">
                    <div className="refer-friend__wrap">
                        <div className="refer-friend__col-lg theme-padding m-border-bottom">
                            
							<h2 className="columns-row__title">
								Rewards Pool
							</h2>
							<div className="columns-row__text">
								<p>
									<span className="bold-font">Match 6/6</span> to win or share the TOP reward in digital currency = 70% of the rewards pool
								</p>
								<p>
									<span className="bold-font">Match 5/6+ bonus number win</span> or share the SECOND reward in digital currency = 20% of the rewards pool
								</p>
								<p>
									<span className="bold-font">Match 5/6 win</span> or share the THIRD reward in digital currency = 10% of the rewards pool
								</p>
								<p>
									If you don't win or share 1 of the top 3 rewards above you’re eligible for MORE:
								</p>
								<p>
									<span className="bold-font">At <img src="./images/heading.svg" className="krypto6home krypto-img-sm mlr-5" alt="what is krypto 6" /> we are committed to our ALWAYS-WIN philosophy</span>
								</p>
								<p>
									A secondary ticket will be issued if you didn't win. This complimentary ticket has one lifecycle for the upcoming draw.
								</p>
								<p>
									<span className="bold-font">Secondary prizes could include:</span>
								</p>
								<ul>
									<li>Exclusive access to <img src="./images/heading.svg" className="krypto6home krypto-img-sm mlr-5" alt="what is krypto 6" /> experiences, virtual and real-world. </li>
									<li>Brand partner rewards, virtual and real-world to be announced per <img src="./images/krypto6.svg" className="krypto6home-sm" alt="krypto 6" /> draw. </li>
								</ul>
							</div>

                        </div>
                        <div className="refer-friend__col-sm" style={{ backgroundImage: `url(./images/about-decor-bg.svg)` }} />
                    </div>
                </div>
            </div>

			<ColumnsRow
				className="columns-row--type12 d-none"
				content1={
					<>
						<h2 className="columns-row__title">
							Title
						</h2>
						<div className="columns-row__text">
							<p>User will receive a NFT with their SIX numbers (fiat processor or wallet address to transfer funds)</p>
						</div>
					</>
				}
			/>

			<ColumnsRow
				className="columns-row--type13 d-none"
				content1={
					<>
						<h2 className="columns-row__title">Title</h2>
						<div className="columns-row__text">
							<p>Pick SIX or Quick SIXSend playnow 6/49 screenshots (from whatsapp)</p>
						</div>
					</>
				}
				content2={
					<>
						<h2 className="columns-row__title">Title</h2>
						<div className="columns-row__text">
							<p>Winners Numbers (past) & Jackpot Winners data box</p>
						</div>
					</>
				}
			/>
			
		</>
	)
}

export default Six;

import React from 'react';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

const MainLayout = () => {

    console.log(window.location)

	return (
        <div className="wrapper">
            <Header />

            <main>

                <div className="logo-box">
                    {window.location.pathname == '/logged-in' ? <></> : <div className="container">
                        <div className="logo-box__wrap">
                            <div className="ukraine-only ukraine-bg">
                                <img src="./images/ukraine/logo-dark.svg" alt="support ukraine" className="ukraine-logo" />
                            </div>
                            <Link className="logo-box__link" to="/">
                                <img src="./images/heading.svg" alt="kryp.to" className="img" />
                            </Link>
                        </div>
                    </div>}
                    
                </div>

                <Outlet />

            </main>

            <Footer />
        </div>
	)
}

export default MainLayout;

import React from 'react';

import BoxBg from '../../components/BoxBg';

import './style.scss';

const FAQ = () => {
    return (
        <>
            <BoxBg imageUrl="about-image-heading-bg.png" title="FAQ" className="box-bg--about" />
            <div className="container">
                <div className="static-content">
                    <div className="static-content__box">
                        <h2>Why blockchain?</h2>
                        <p>By building on a blockchain kryp.to can create the global decentralized platform that connects communities around the world</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Who is behind this project?</h2>
                        <p>kryp.to is built by a team of serial entrepreneurs and founders, with backgrounds in fintech, blockchain, welltech, actuarial sciences, & proptech. Passionate about philanthropy & on a journey to make it inclusive for everyone</p>
                    </div>
                    <div className="static-content__box">
                        <h2>How much traffic can the platform handle?</h2>
                        <p>Over 50,000 transactions per second</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Which wallet will I need?</h2>
                        <p>We will launch with various wallets. Our wallet partners include SOL Native wallets such as Phantom.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Does kryp.to control/hold our funds?</h2>
                        <p>kryp.to partners with custodians and doesn't offer any custody or storage services. If a community user makes a contribution; a transparent, contract will be created on the blockchain which will settle based upon the experience outcome and remit funds and or services accordingly to the user.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Where is kryp.to based?</h2>
                        <p>kryp.to is a remote first company, our team is global & concentrated in Vancouver, Canada</p>
                    </div>
                    <div className="static-content__box">
                        
                    </div>
                </div>
            </div>
        </>
    )
};

export default FAQ;

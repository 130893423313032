import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import Switch from "../Switch";
import SocialIcons from "../SocialIcons";

import "./style.scss";

import { useStytch, useStytchSession } from '@stytch/stytch-react';

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};
const themeColors = [
  "#fff",
  "#67e282",
  "#bb65ea",
  "#51b8ea",
  "#f3d357",
  "#d65d5d",
  "#5ed0d0",
  "#ff87b9",
  "#bbe85d",
];
//const darkthemeColors = ['#fff', '#67e282', '#bb65ea', '#51b8ea', '#f3d357', '#ff6815', '#d65d5d', '#5ed0d0', '#ff87b9', '#bbe85d'];
let startColor = getRandomInt(themeColors.length);
console.log(startColor);
localStorage.setItem("theme", "light");
localStorage.setItem("bgColor", themeColors[startColor]);

const links = [
  { id: 1, linkTo: "/about", text: "About us" },
  { id: 3, linkTo: "/how-it-works", text: "How it works" },
  { id: 5, linkTo: "/six", imageSrc: "/images/krypto6.svg" },
  { id: 9, linkTo: "/logged-in", text: "Contribute" },
  { id: 14, linkTo: "/proceeds", text: "Proof of Proceeds" }, 
  { id: 16, linkTo: "/winners", text: "Winners" },
  { id: 4, linkTo: "/merch", text: "Merch" },
  { id: 8, linkTo: "/charity-nomination", text: "Nominate a charity" },
  { id: 11, linkTo: "/news", text: "News" },
  { id: 15, linkTo: "/partners", text: "Brand Partners" },
  { id: 10, linkTo: "/ventures", imageSrc: "/images/heading.svg", text: "VENTURES" }, 
  { id: 13, linkTo: "/pledge", imageSrc: "/images/heading.svg", text: "PLEDGE" },

  { id: 2, linkTo: "/contact", text: "Contact us" },
  //{ id: 6, linkTo: "/logged-in", text: "play the SIX for free" },
  // { id: 7, linkTo: "/via", text: "kryp.to VIA" },\
  { id: 17, linkTo: "/", text: " " }
];

const Header = () => {

  const session = useStytchSession()
  const stytchClient = useStytch()

  let logged_in = true;

  if (session === null) logged_in = false

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const onThemeChange = (e) => {
    setChecked(e.target.checked);

    if (checked) {
      startColor += 1;
      startColor = startColor % 10;
      console.log(startColor);

      document.body.style.backgroundColor = themeColors[startColor];
      document.querySelector(".header__nav").style.backgroundColor =
        themeColors[startColor];

      document.body.classList.remove("dark");
      document.body.classList.add("light");
      localStorage.setItem("theme", "light");
      localStorage.setItem("bgColor", themeColors[startColor]);
    } else {
      document.body.style.backgroundColor = themeColors[startColor];

      document.body.classList.remove("light");
      document.body.classList.add("dark");
      localStorage.setItem("theme", "dark");
      localStorage.setItem("bgColor", themeColors[startColor]);
    }
  };

  const jump_to_tickets = () => {
    if(window.location.pathname != '/logged-in') {
      window.location = '/logged-in'
    } else {
      document.getElementById("jump_tickets").scrollIntoView({behavior: 'smooth'});
    }
  }

  const checkTheme = () => {
    const localStorageTheme = localStorage.getItem("theme");
    const localStorageBgColor = localStorage.getItem("bgColor");

    document.body.style.backgroundColor = localStorageBgColor;
    document.querySelector(".header__nav").style.backgroundColor =
      localStorageBgColor;

    if (localStorageTheme === "light") {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
      setChecked(false);
    } else {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
      setChecked(true);
    }
  };

  useEffect(() => {
    checkTheme();
  }, [checked]);

  useEffect(() => {
    const sun = document.querySelector(".img-sun");
    sun.addEventListener("click", () => {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    });

    const moon = document.querySelector(".img-moon");
    moon.addEventListener("click", () => {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    });
  }, []);

  const reroute = () => {
    if (logged_in) {
      stytchClient.session.revoke();
    }
    else {
      navigate("/login");
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__wrap">
          <div
            className={classNames("header__btn-menu", { active: open })}
            onClick={() => setOpen(!open)}
          >
            <div className="header__btn-line"></div>
            <div className="header__btn-line"></div>
            <div className="header__btn-line"></div>

            <div className="age-req">
              <span>18+</span>
            </div>
          </div> 
          <div className="header__box-rigth">
            <div className="header__nav-theme header__nav-theme--sm">
              <img src="./images/sun.svg" alt="" className="img-sun" />
              <Switch onChange={onThemeChange} checked={checked} size="sm" />
              <img src="./images/moon.svg" alt="" className="img-moon" />
            </div>
            {logged_in ? 
              <button
                className="btn btn--lg refer-friend__button main-btn rewards__btn"
                onClick={jump_to_tickets}
              > My Rewards
              </button> : <div />
            }
            <button
              className="btn btn--lg refer-friend__button main-btn login__btn"
              type="submit"
              onClick={reroute}
            >
              {!logged_in ? "Log In" : "Log Out"}
            </button>
          </div>

          <nav className={classNames("header__nav", { active: open })}>
            <div className="container">
              <div className="header__nav-head">
                <Link
                  className="header__nav-col-sm header__nav-col-logo"
                  to="/"
                  onClick={() => setOpen(false)}
                >
                  <img
                    src="./images/heading.svg"
                    alt="kryp.to"
                    className="img"
                  />
                </Link>
                <div className="header__nav-col-lg">
                  <div className="header__nav-social">
                    <SocialIcons size="lg" />
                  </div>
                  <div className="header__nav-theme">
                    <img src="./images/sun.svg" alt="" className="img-sun" />
                    <Switch onChange={onThemeChange} checked={checked} />
                    <img src="./images/moon.svg" alt="" className="img-moon" />
                  </div>
                </div>
              </div>

              <div className="header__nav-body">
                {links.map((el, index) => {
                  return (
                    <div
                      onClick={() => setOpen(false)}
                      className={index % 2 === 0 ? "header__nav-col-sm" : "header__nav-col-lg"}
                      key={el.id}
                    >
                      <Link to={el.linkTo} className="header__nav-link">
                        {el.imageSrc && <img src={el.imageSrc} alt={el.text} className="krypto6home-link-header" />}
                        {el.text}
                      </Link>
                    </div>
                  );
                })}
                {/* <div className="header__nav-col-lg header__nav-col-built"></div> */}
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="border___top">
        <p className="header___text">
          The World's 1st ‘Philanthropy as a Service’ Platform, Empowering Charitable Fundraising
        </p>
      </div>
    </header>
  );
};

export default Header;

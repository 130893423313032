import React from 'react';
import classNames from 'classnames';

import './style.scss';

const SocialIcons = ({ size }) => {
    return (
        <div className={classNames('social-icons', { 'social-icons--lg': size === 'lg' })}>
            <div className="social-icons__item">
                <a href="https://www.tiktok.com/@kryp.to" className="social-icons__link" target="_blank" rel="noreferrer">
                    <i class="bi bi-tiktok"></i>
                </a>
            </div>
            <div className="social-icons__item">
                <a href="https://twitter.com/krypto6ix" className="social-icons__link" target="_blank" rel="noreferrer">
                    <i class="bi bi-twitter"></i>
                </a>
            </div>
            <div className="social-icons__item">
                <a href="https://www.instagram.com/gokrypto/" className="social-icons__link" target="_blank" rel="noreferrer">
                    <i class="bi bi-instagram"></i>
                </a>
            </div>
            {/* <div className="social-icons__item">
                <a href="https://discord.gg/CD8dVEnm" className="social-icons__link" target="_blank" rel="noreferrer">
                    <i class="bi bi-discord"></i>
                </a>
            </div> */}
        </div>
    )
}

export default SocialIcons;

import React from 'react';

import BoxBg from '../../components/BoxBg';
import BoxText from '../../components/BoxText';

import './style.scss';


const Partners = () => {
	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="Brand Partners" className="box-bg--how-it-works" />

			<BoxText className="m-02">
				<div className='row-3'>
					<div className='col-3 valign'>
						<a href="https://comkids.ca/" rel="noreferrer" target="_blank"><img src="./images/charities/comkids.png" className="partner-logo" alt="comkids" /></a>
					</div>
					<div className='col-3 valign'>
						<a href="https://www.risenshine.app/" rel="noreferrer" target="_blank"><img src="./images/slider/rs.webp" className="partner-logo w-300" alt="rise and shine" /></a>
					</div>
					<div className='col-3 valign'>
						<a href="https://6ix.buzz/" rel="noreferrer" target="_blank"><img src="./images/6buzztv.png" className="partner-logo gray-img" alt="6ix.Buzz" /></a>
					</div>
				</div>
			</BoxText>

		</>
	)
}

export default Partners;

import React from "react";

import BoxBg from "../../components/BoxBg";
import ColumnsRow from "../../components/ColumnsRow";
import BoxText from "../../components/BoxText";
import ArrowLink from "../../components/ArrowLink";

import "./style.scss";

const HowItWorks = () => {
  return (
    <>
      <BoxBg
        imageUrl="how-it-works-heading-bg.png"
        title="How it works"
        className="box-bg--how-it-works"
      />

      <BoxText className="box-text--how-it-works">
        <h2 className="mobile-20">
          At <img src="./images/heading.svg" className="krypto6home krypto-img-sm" alt="what is krypto 6" /> we create engaging experiences to make community-driven contributions fun and rewarding
          <br/><br/>
          You can contribute digital currencies in real-time, by simply connecting your digital wallet... it's that easy! 
          <br/><br/>
          It could be your turn to win the <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> 
          <br/><br/>
          To learn more about our philanthropic platform, <a href="https://vimeo.com/706434672" target="_blank" class="bold-text" rel="noreferrer">click here</a> to watch the kryp.to introduction video. 
        </h2>
      </BoxText>

      <ColumnsRow
        className="columns-row--type07"
        content1={
          <>
            <h2 className="columns-row__title">Transparency</h2>
            <div className="columns-row__text">
              <p>
                As the world’s first philanthropy as a service platform, your
                ethical contributions will be proportionally allocated in
                real-time to charitable organizations using blockchain & smart
                contract technology.
              </p>
            </div>
          </>
        }
        imageUrl="how-it-works-bg.png"
      />

      <ColumnsRow
        className="columns-row--type10"
        content1={
          <>
            <div className="columns-row__text">
              <h2 className="columns-row__title"><img src="./images/heading.svg" className="krypto6home ml-0" alt="what is krypto 6" /> PLEDGE</h2>
              <p className="mb-45">
                The <img src="./images/heading.svg" className="krypto6home krypto-img-sm" alt="what is krypto 6" /> PLEDGE provides our community winners a real-time
                option to contribute all or part of their rewards to our
                beneficiaries list and/or a charity of their choice.
              </p>
              <p>
                Learn more ways to{" "}
                <a href="pledge" className="bold-text">
                  PLEDGE
                </a>
              </p>
            </div>
          </>
        }
        imageUrl="six-image-bg.svg"
      />

      <ColumnsRow
        className="columns-row--type08"
        content1={
          <>
            <div className="columns-row__box">
              <ArrowLink to="/logged-in" text="Contribute Now" />
            </div>
            <div className="columns-row__box">
              <ArrowLink to="/charity-nomination" text="Nominate a Charity" />
            </div>
          </>
        }
        imageUrl="how-it-works-links.svg"
      />
    </>
  );
};

export default HowItWorks;

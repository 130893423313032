import React from 'react';

import Button from '../../components/Button';

import './style.scss';

const NotFound = () => {
    return (
        <div className="not-found">
            <div className="not-found__row">
                <div className="not-found__cell">
                    <img src="./images/not-found.svg" alt="404" />
                </div>
            </div>
            <div className="not-found__row">
                <div className="not-found__cell">
                    <div className="not-found__description-wrap">
                        <span className="not-found__description">Oops, our bad.</span>
                        <Button link="/" text="Back to home" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ColumnsRow from "../../components/ColumnsRow";
import BoxText from "../../components/BoxText";
import BoxBg from "../../components/BoxBg";
import Select from "../../components/Select";
import DropDownSelect from "../../components/DropDownSelect";

import "./style.scss";

import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  //WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

const upcomingOptions = [
  {
    id: 1,
    value: "one",
    text: "TBA",
  },
];

const Via = () => {
  const navigate = useNavigate();

  const reroute = () => {
    navigate("/logged-in");
  };

  // useEffect(() => {
  // 	document.querySelector('#btn-contribute .wallet-adapter-button').innerHTML = `
  // 		<img src="./images/contribute.png" alt="contribute by krypto"/>
  // 	`;
  // }, []);

  return (
    <>
      <BoxBg
        imageUrl="via-image-heading-bg.png"
        title="Very Important Avatar"
        className="box-bg--via"
      />

      <BoxText className="m-02">
        <p className="mobile-text-header">
          VIA is your access to the real-world and beyond.
          <br className="mobile-only" />
          <img
            src="./images/mascot.svg"
            alt="mascot"
            className="mascot page-mascot"
          />
        </p>
      </BoxText>

      <ColumnsRow
        className="columns-row--type09 mobile-col-reverse-01"
        content1={
          <>
            <h2 className="columns-row__title">
              Make a contribution to receive access:
            </h2>
            <div className="columns-row__text">
              <p>
                By making a contribution amount set by our VIA partners, you
                will get access to amazing VIA experiences (virtual and
                real-world) e.g. concerts, sports, special events, exclusive
                parties, products and more!
              </p>
              <p>
                Real-time allocations will be made to our VIA partners charity
                or social cause of choice and to our beneficiaries list.
              </p>
              <div className="six-jackpot__label text-01">
                Upcoming VIA experiences:
              </div>
              <div className="six-jackpot__wrap input-01">
                <div className="six-jackpot__select">
                  <DropDownSelect options={upcomingOptions} />
                </div>
              </div>
            </div>
            <div className="columns-row__box mobile-center">
              <button
                className="btn btn--lg main-btn"
                type="submit"
                onClick={reroute}
              >
                Make a Contribution
              </button>
            </div>
          </>
        }
        imageUrl="via.svg"
      />
    </>
  );
};

export default Via;

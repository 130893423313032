import React from 'react';
import classNames from 'classnames';

import './style.scss';

const FormBox = ({ label, placeholder, type = 'text', id, textarea, defaultField, ...rest }) => {
    return (
        <>
            {textarea ? (
                <div className="form-box form-box--textarea">
                    <textarea className="form-box__field" id={id} placeholder={placeholder} {...rest}></textarea>
                    {label ? <label className="form-box__label" for={id}>{label}</label> : null}
                </div>
            ) : (
                <div className={classNames('form-box',  { 'form-box--default': defaultField } )}>
                    {label ? <label className="form-box__label" for={id}>{label}</label> : null}
                    <input type={type} className="form-box__field" id={id} placeholder={placeholder} {...rest} />
                </div>
            )}
        </>
    )
};


export default FormBox;
import React from 'react';

import BoxBg from '../../components/BoxBg';
import FormBox from '../../components/FormBox';

import './style.scss';

const CharityNomination = () => {
	return (
		<>
			<BoxBg imageUrl="charity-nomination-image-heading-bg.png" title="Nominate a charity" className="box-bg--charity-nomination" />

			<div className="charity-nomination">
                <div className="container">
                    <div className="charity-nomination__wrap">
                        <div className="charity-nomination__col-lg">
                            <form>
                                <div className="charity-nomination__row">
                                    <FormBox label="Charity Name" id="input-charity-name" required />
                                </div>
                                <div className="charity-nomination__row">
                                    <FormBox label="Charity Website" id="input-charity-website" required />
                                </div>
                                <div className="charity-nomination__row">
                                    <FormBox label="Social Handle" id="input-social-handle" />
                                </div>
                                <div className="charity-nomination__row">
                                    <FormBox label="Your Email" id="input-referred-by" required />
                                </div>
                                <div className="charity-nomination__row charity-nomination__row--btn">
                                    <button className="btn btn--lg charity-nomination__button" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div className="charity-nomination__col-sm" style={{ backgroundImage: `url(./images/referral-image-bg.svg)` }} />
                    </div>
                </div>
            </div>
		</>
	)
}

export default CharityNomination;

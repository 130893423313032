import React from "react";

import BoxBg from "../../components/BoxBg";
import ColumnsRow from "../../components/ColumnsRow";
import BoxText from "../../components/BoxText";


const HowToContribute = () => {
  return (
    <>
      <BoxBg
        imageUrl="how-it-works-heading-bg.png"
        title="How to contribute"
        className="box-bg--how-it-works"
      />

      <BoxText className="box-text--how-it-works htc-page">
        <h2 className="columns-row__title text-left">
          How to contribute at <img src="./images/heading.svg" className="krypto6home" alt="what is krypto 6" />
        </h2>
        <h2 className="mobile-20">
          1. After you download the mobile app for MetaMask wallet for Ethereum or Phantom wallet for Solana
          <br/><br/>
          2. Go to the MetaMask or Phantom browser in your wallet app and enter <b>kryp.to</b>
          <br/><br/>
          3. Click on the contribute or login button and enter your email
          <br/><br/>
          4. You will receive a one-time login code in your email that's time sensitive, copy & paste the code to login 
          <br/><br/>
          5. Select Ethereum (ETH) if you're using your MetaMask wallet or select Solana (SOL) if you're using your Phantom wallet
          <br/><br/>
          6. Enter the amount you want to contribute, this is done by selecting the number of complimentary tickets/rewards you'll receive
          <br/><br/>
          The minimum contribution is $5 USD and it increases in multiples of $5 USD, there's no limit
          <br/><br/>
          {/* <span className="smaller-font">Remove *Select ComKids in the drop-down if you're visiting kryp.to directly, it's not required with kryp.to/ComKids</span> 
          <br/><br/> */}
          <span className="smaller-font">e.g. 1 ticket = $5 USD or 10 tickets = $50 USD </span> 
          <br/><br/>
          7. Click the contribute button and a pop-up will appear to confirm the contribution in your mobile wallet app
          <br/><br/>
          8. Scroll down and select the refresh button on <img src="./images/krypto6.svg" className="krypto6home krypto-six-sm" alt="what is krypto 6" /> after you've approved the contribution
          <br/><br/>
          You can select the 'My Rewards' button or manually scroll down to view your <img src="./images/krypto6.svg" className="krypto6home krypto-six-sm" alt="what is krypto 6" /> tickets
        </h2>
      </BoxText>

      <ColumnsRow
        className="columns-row--type07"
        content1={
          <>
            <h2 className="columns-row__title">Account features:</h2>
            <div className="columns-row__text">
              <h2 className="mobile-20">
                • Your personal contribution totals and rewards will be shown in your account
                <br/><br/>
                •<img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> rewards pool in real-time
                <br/><br/>
                • Contributions to charities in real-time
              </h2>
            </div>
          </>
        }
        imageUrl="six-image-bg.svg"
      />

      <BoxText className="box-text--how-it-works htc-page">
        <h2 className="columns-row__title text-left">
          Download MetaMask wallet for Ethereum or Phantom wallet for Solana:
        </h2>
        <h2 className="mobile-20">
          <b>Step 1.</b>
          <br/><br/>
          For Ethereum aka ETH, download the app for MetaMask wallet on your mobile device
          <br/><br/>
          For Solana aka SOL, download the app for Phantom wallet on your mobile device
          <br/><br/>
          <b>Step 2.</b>
          <br/><br/>
          Copy and safely store your private keys (password) that are attached to the MetaMask wallet or Phantom wallet. Write the private keys on paper and store them in different locations, in case you misplace one of the copies
          <br/><br/>
          If you lose your private keys, you won't be able to access your MetaMask wallet or Phantom wallet.
        </h2>
      </BoxText>

    </>
  );
};

export default HowToContribute;

import React from "react";
import { useEffect, useState } from "react";

import BoxBg from '../../components/BoxBg';
import BoxText from '../../components/BoxText'
import DropDownSelect from "../../components/DropDownSelect";
import PartnersSlider from "../../components/PartnersSlider";

import './style.scss';

import axios from 'axios';

const currency = [
  { id: 1, value: 'usd_total', text: 'USD' },
  { id: 2, value: 'sol_total', text: 'SOL' },
  { id: 5, value: 'eth_total', text: 'ETH' }
];

const totals = [
	{ id: 1, value: 'total', text: 'Total' },
  { id: 2, value: 'this', text: 'Current' },
];

const Proceeds = () => {
	const [_currency, setCurrency] = useState('usd_total')
  const [charityCurrency, setCharityCurrency] = useState('usd_total')
  const [period, setPeriod] = useState('total')
  const [charityPeriod, setCharityPeriod] = useState('total')

  const [polled, setPolled] = useState(false)
  const [compound, setCompound] = useState({
    total: {
      sol_total: 0,
      eth_total: 0,
      usd_total: 0
    },
    this: {
      sol_total: 0,
      eth_total: 0,
      usd_total: 0
    }
  })

	if(!polled) {
    setPolled(true)
    axios.post('https://smartpaywebhook.justusmoeller2.repl.co/get_compound', {}).then(res => {
			console.log(res.data)
      setCompound(res.data)
	})
  }

	const changeCurrency = (currencyValue) => {
    setCurrency(currencyValue);
  };

  const changePeriod = (changePeriod) => {
    setPeriod(changePeriod);
  };

  const changeCharityCurrency = (changeCharityCurrency) => {
    setCharityCurrency(changeCharityCurrency);
  };

  const changeCharityPeriod = (changeCharityPeriod) => {
    setCharityPeriod(changeCharityPeriod);
  };

	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="Proof of Proceeds" className="box-bg--how-it-works" />

			<div className="six-jackpot">
        <div className="container">
          <div className="six-jackpot__outer set-center">
            <div className="six-jackpot__box">
              <div className="six-jackpot__label">Real-time <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> rewards</div>
              <div className="six-jackpot__wrap jackpot-selects">
                <div className="six-jackpot__value">{_currency == 'usd_total' ? '$ ' + (compound[period][_currency]*.4).toFixed(2) : (compound[period][_currency]*.4).toFixed(4)}</div>
                <div className="six-jackpot__select mr-5">
                  <DropDownSelect options={currency} onChange={changeCurrency} />
                </div>
                <div className="six-jackpot__select ml-5">
                  <DropDownSelect options={totals} onChange={changePeriod} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

			<div className="six-jackpot six-jackpot--cols">
        <div className="container">
          <div className="six-jackpot__outer set-center">
            <div className="six-jackpot__box">
              <div className="six-jackpot__label">Contributions to charities</div>
              <div className="six-jackpot__wrap jackpot-selects">
                <div className="six-jackpot__value">{charityCurrency == 'usd_total' ? '$ ' + (compound[charityPeriod][charityCurrency]*.5).toFixed(2) : (compound[charityPeriod][charityCurrency]*.5).toFixed(4)}</div>
                <div className="six-jackpot__select mr-5">
                  <DropDownSelect options={currency} onChange={changeCharityCurrency} />
                </div>
                <div className="six-jackpot__select ml-5">
                  <DropDownSelect options={totals} onChange={changeCharityPeriod} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

		</>
	)
}

export default Proceeds;

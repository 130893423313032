import React, { useState } from 'react';

const DropDownSelect = ({ options, onChange, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    const allDropdowns = document.querySelectorAll('.dropdown-select');
    allDropdowns.forEach((dropdown) => {
      const options = dropdown.querySelector('.dropdown-select-options');
      if (options) {
        options.style.display = 'none';
      }
    });
    onChange(option.value);
  };

  return (
    <>
      <div className='dropdown-select' {...rest}>
        <div className='button' onClick={() => setIsOpen(!isOpen)}>
          {selectedOption ? selectedOption.text : 'Select'}
        </div>
        {isOpen && (
          <div className='dropdown-select-options'>
            {options.map((option, index) => {
              return (
                <div
                  key={option.id || index}
                  value={option.value}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.text}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default DropDownSelect;

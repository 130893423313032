import React, { useEffect } from 'react';
import { useStytch } from '@stytch/stytch-react';

export const Authenticate = () => {
  const stytchClient = useStytch();

  useEffect(() => {
      const token = new URLSearchParams(window.location.search).get('token');
      console.log(token)

      console.log(Object.getOwnPropertyNames(stytchClient).filter(item => typeof stytchClient[item] === 'function'))
      stytchClient.magicLinks.authenticate(token, {
        session_duration_minutes: 60,
      }).then(r => {
        window.location = '/logged-in'
      }).catch(err => {
          window.location = '/login'
      });
  }, [stytchClient]);

  return <div>Loading</div>;
};
import React from 'react';

import BoxBg from '../../components/BoxBg';
import ColumnsRow from '../../components/ColumnsRow';
import BoxText from '../../components/BoxText';

import pdf from '../../../src/assets/kryp.to-introduction.pdf';
import './style.scss';

const About = () => {
	return (
		<>
			<BoxBg imageUrl="about-image-heading-bg.png" title="About" className="box-bg--about" />

			<ColumnsRow
				className="columns-row--type05"
				content1={
					<>
						<h2 className="columns-row__title mobile-text-header">
							<img src="./images/heading.svg" className="krypto6home ml-0" alt="what is krypto 6" /> - is pioneering <b>contribute to earn</b> as the world's 1st philanthropy as a service platform, empowering charitable fundraising.
						</h2>
						<div className="columns-row__text">
							<p>
								We distribute your contributions in real-time to charitable organizations around the world! 
							</p>
						</div>
					</>
				}
				imageUrl="about-decor-bg.svg"
			/>

			<BoxText>
				<p>
					A team of serial entrepreneurs and founders, with backgrounds in fintech, blockchain, proptech & actuarial science. Passionate about philanthropy, on a journey to make it inclusive for everyone.
				</p>
			</BoxText>
		
			<BoxBg imageUrl="about-image-bg.png" />

			<BoxText>
				<p>
					We believe in ALWAYS-WIN, if you don't win the <img src="./images/krypto6.svg" className="krypto6home" alt="krypto 6" /> jackpot or any secondary rewards for your contributions, you'll receive a complimentary ticket on us! 
					<br/><br/>
					The complimentary ticket has one life-cycle for the next <img src="./images/krypto6.svg" className="krypto6home" alt="krypto 6" /> draw.
				</p>
			</BoxText>
		</>
	)
}

export default About;

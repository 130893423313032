import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';

import './style.scss';

const PartnersSlider = () => {
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);

    return (
        <div className="partners">
            <div className="partners__prev" ref={navigationPrevRef}>
                <img src="./images/arrow.svg" alt="" />
            </div>
            <Swiper
                spaceBetween={30}
                slidesPerView={5}
                loop
                modules={[Navigation]}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onSwiper={(swiper) => {
                    setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
            
                        swiper.navigation.destroy();
                        swiper.navigation.init();
                        swiper.navigation.update();
                    })
                }}
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                        centeredSlides: true,
                    },
                    600: {
                        slidesPerView: 4,
                    },
                    1024: {
                        slidesPerView: 5,
                    },
                }}
                className="partners__swiper"
            >
                <SwiperSlide>
                    <a href="https://comkids.ca/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/comkids.webp" alt="com kids" className="logo-comkids" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.thebirthdaywishproject.com/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/tbwp.png" alt="the birthday wish project" className="logo-tbwp" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.snofamilyfoundation.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/logo-snf.png" alt="sno culture" className="logo-snf" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://womenandyouthfoundation.com/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/women-and-youth.png" alt="women and youth foundation" className="logo-mission" loading="lazy" />
                    </a>
                </SwiperSlide>

                {/* <SwiperSlide>
                    <a href="https://letsfcancer.com/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/slide-2.svg" alt="lets f cancer" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://pcmfusa.org/en/ambassadors-advisors/charles-leclerc" target="_blank" rel="noreferrer">
                        <img src="./images/slider/logo-pcmf.svg" alt="princess charlene of monaco" className="logo-mission" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://aokifoundation.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/slide-1.svg" alt="aoki foundation" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.mission44.org/about" target="_blank" rel="noreferrer">
                        <img src="./images/slider/mission44.png" alt="mission 44" className="logo-mission" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.nufoundation.org.uk/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/ncf.png" alt="new castle foundation" className="logo-ncf" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://onepercentfortheplanet.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/slide-3.svg" alt="one percent for the planet" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://theoceancleanup.com/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/slide-4.svg" alt="the ocean cleanup" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://stompoutbullying.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/slide-5.svg" alt="stomp out bullying" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.bcchf.ca/our-foundation/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/bcch.png" alt="bc childrens hospital foundation" className="logo-bcch" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://wck.org/story" target="_blank" rel="noreferrer">
                        <img src="./images/slider/wck.png" alt="world central kitchen" className="logo-wck" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.shaqfoundation.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/shaq.png" alt="shaquille o'neal foundation" className="logo-shaq" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.coreresponse.org/about-us" target="_blank" rel="noreferrer">
                        <img src="./images/slider/core.png" alt="core response" className="logo-core" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://cares.nba.com/mission/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/nba.png" alt="nba cares" className="logo-nba" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.eatlearnplay.org/founders" target="_blank" rel="noreferrer">
                        <img src="./images/slider/elp.png" alt="eat learn play" className="logo-elp" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.hockeycanada.ca" target="_blank" rel="noreferrer">
                        <img src="./images/slider/hcf.png" alt="hockey canada foundation" className="logo-hcf" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://pridefoundation.org/about-us/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/pride.svg" alt="pride foundation" className="logo-pride" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.climaterealityproject.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/crp.png" alt="the climate reality project" className="logo-crp" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://nbafoundation.nba.com/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/nbaf.png" alt="nba foundation" className="logo-nbaf" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.skep.gr/en" target="_blank" rel="noreferrer">
                        <img src="./images/slider/skep.png" alt="skep" className="logo-skep" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.charitywater.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/cw.svg" alt="charity water" className="logo-cw" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.lebronjamesfamilyfoundation.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/lb.png" alt="lb" className="logo-lb" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://kse.ua/support/donation/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/kse.png" alt="kse" className="logo-kse" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://baby2baby.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/b2b.png" alt="baby 2 baby" className="logo-b2b" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://solana.foundation/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/sol.svg" alt="solana" className="logo-sol" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://outdoorafro.com/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/oda.png" alt="outdoor afro" className="logo-oda" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.muskfoundation.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/mf.png" alt="musk foundation" className="logo-mf" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.thel40foundation.com/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/l4.webp" alt="l40 foundation" className="logo-l4" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://leanin.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/logo-li.svg" alt="lean in" className="logo-hc" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.hopechicago.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/hope.png" alt="hope chicago" className="logo-hc" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://optionb.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/logo-ob.svg" alt="option b" className="logo-hc" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://nationalpcf.org/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/npcf.png" alt="national pcf" className="logo-hc" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://foodbankscanada.ca/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/fbc2.png" alt="food banks canada" className="logo-hc" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://biggreen.org/donatedao/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/logo-bgr.png" alt="big green" className="logo-hc" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://childrensmiraclenetworkhospitals.org/about-us/" target="_blank" rel="noreferrer">
                        <img src="./images/slider/cmnh.svg" alt="childrens miracle network hospitals" className="logo-hc" loading="lazy" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.87running.org" target="_blank" rel="noreferrer">
                        <img src="./images/slider/87.png" alt="87 running" className="logo-mission" loading="lazy" />
                    </a>
                </SwiperSlide> */}
            </Swiper>
            <div className="partners__next" ref={navigationNextRef}>
                <img src="./images/arrow.svg" alt="" />
            </div>
        </div>
    )
}

export default PartnersSlider;

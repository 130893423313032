import React from 'react';

import BoxBg from '../../components/BoxBg';

import './style.scss';

const Terms = () => {
    return (
        <>
            <BoxBg imageUrl="about-image-heading-bg.png" title="Terms of Use" className="box-bg--about" />
            <div className="container">
                <div className="static-content">
                    <div className="static-content__box">
                        <h2>1. Acceptance of the Terms of Use.</h2>
                        <p>By accessing or using the Site, including accessing or using any online service or information available through the Site, you are accepting and agreeing to these Terms of Use, the Privacy Statement, as well as Other Terms that apply to any particular Site.  Your continued use of the Site confirms your acceptance of the Terms in force at the time you use the Site. Please read the Terms carefully before using the Site.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>2. Changes to the Services and Terms of Use:</h2>
                        <p>Except where prohibited by applicable law, we reserve the right to change the Services or these Terms of Use at any time without notice. Your continued access to or use of the Services after any changes to these Terms of Use indicates your acceptance of such changes. It is your responsibility to review these Terms of Use regularly.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>3. Access to the Services</h2>
                        <p>Subject to your compliance with these Terms of Use, we will use commercially reasonable efforts to make the Use available to you in accordance with these Terms of Use, which Services may assist you to:</p>
                        <ul>
                            <li>create and use digital currency wallets;</li>
                            <li>contribute with certain digital currencies;</li>
                            <li>track, transfer, and manage such digital currencies;</li>
                            <li>create and use a digital identity profile for use with us.</li>
                        </ul>
                        <h2 class="mt-2rem">Contributions</h2>
                        <ul>
                            <li>Processing, collecting, and distributing community-driven contributions;</li>
                            <li>Providing blockchain validated information to Charities and Foundations to confirm contributions for a charitable gift receipt;</li>
                            <li>Soliciting contributors for support of our services;</li>
                            <li>Inviting contributors to attend events and/or to receive complimentary rewards;</li>
                            <li>Providing contribution related communications to Charity and Foundation stakeholders;</li>
                            <li>Opting out. The contributor can be removed from selected or all contacts with our platform by contacting customer support: hello@kryp.to</li>
                        </ul>
                        <p>
                            Notwithstanding the foregoing, we retain the right to deny or suspend your use of the Services, at any time and for any reason, including for any violation of these Terms of Use, for scheduled maintenance, or to address any emergency security concerns.
                        </p>
                    </div>
                    <div className="static-content__box">
                        <h2>4. Definitions. In these Terms of Use, unless the context clearly otherwise dictates:</h2>
                        <ol className="alphabetical">
                            <li>"applicable law" includes applicable statutes, regulations, judicial precedents, orders in council, and directives of governmental or regulatory authorities;</li>
                            <li>Digital Universe Technologies Inc. d/b/a kryp.to form a binding legal agreement between you and one of the following entities (the applicable entity, “kryp.to”, “us”, “we”, “our”);</li>
                            <li>“Other Terms” means any such other notices, agreements, rules, conditions and legal terms that are applicable to the Site you access or use;</li>
                            <li>"person" includes individuals, corporations, partnerships, joint ventures, associations, trusts, unincorporated organizations, societies, and all other juridical entities recognized by applicable law;</li>
                            <li>“Site” means the website operated by Digital Universe Technologies Inc. that provide information, and in some cases services;</li>
                            <li>“Terms” means collectively these Terms of Use, the Privacy Statement, and Other Terms.</li>
                            <li>“Terms of Use” means these Terms of Use.</li>
                            <li>"you" includes you and any other person on behalf of which you accept any of the Terms of Use.</li>
                        </ol>
                    </div>
                    <div className="static-content__box">
                        <h2>5. Interpretation.</h2>
                        <p>Unless the context otherwise requires, words importing the singular include the plural and vice versa. A provision relating to the discretion or authorization of Digital Universe Technologies Inc. is in such Digital Universe Technologies sole, absolute and unfettered discretion, with no requirement to act reasonably or provide reasons. Where the word “include”, “includes”, “including”, or other variation on “include” is used, such word is deemed to be followed by the words “without limitation”.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>6. Changes to the Site.</h2>
                        <p>Digital Universe Technologies Inc. may in its discretion, without any prior notice and without liability, a) modify, suspend or discontinue any portion of the Site including any Terms, and b) remove or change any content or functionality from the Site.  Digital Universe Technologies Inc, furthermore reserves the right to take any action related to the Site that is required to comply with applicable law.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>7. Termination.</h2>
                        <p>Digital Universe Technologies Inc. may at any time, with or without notice, without liability, and for any reason deny you access to the Site and terminate or suspend your license to use the Site. If Digital Universe Technologies Inc. terminates or suspends your license to use the Site, the Terms will nevertheless continue to apply in respect of your use of the Site prior to such termination or suspension.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>8. Ownership and Intellectual Property.</h2>
                        <p>Digital Universe Technologies Inc. grants to you a revocable, personal, limited, non-sublicensable, non-transferable, and non-exclusive license to access and use the Site, as permitted under the Terms, subject to and conditional on your continued compliance with all Terms. All rights, title and interest, including all intellectual property rights, in the Site, including content and Digital Universe Technologies Inc trademarks therein, are and shall remain the property of Digital Universe Technologies Inc. Without limiting the generality of the foregoing, nothing contained in these Terms of Use or on the Site should be construed as granting, by implication, estoppel, or otherwise, any licence or permission or other right to use any content or Digital Universe Technologies Inc. trademark. Unauthorized use of any Site content or Digital Universe Technologies Inc trademark is strictly prohibited. Nothing in these Terms of Use should be construed as granting any right to use the Site for a commercial or other non-personal purpose.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>9. Unacceptable Use. You will not:</h2>
                        <ol className="alphabetical">
                            <li>use the Site to violate any applicable law or any person’s legal rights; </li>
                            <li>interfere with the functioning of the Site in any way;</li>
                            <li>use the Site to transmit any virus, Trojan horse, worm, or other software, script or code the effect or intent of which is to permit unauthorized access to, or to alter, disable, encrypt, erase, or otherwise harm, any computer, systems, software or data;</li>
                            <li>decrypt, decompile, disassemble or reverse engineer the Sites or any software or systems used to provide it;</li>
                            <li>access or use any part of the Sites that is (expressly or implicitly) not intended for use by you; </li>
                            <li>use any non-Digital Universe Technologies Inc automation code in relation to the Site (including any “bot” or “spider”);</li>
                            <li>collect or harvest any information from the Site in a bulk or systematic way;</li>
                            <li>create derivative or competitive works from the Site;</li>
                            <li>link to the Site by way of framing technology or deep links, or otherwise incorporate any portion of the Site into any product or service;</li>
                            <li>copy, modify, adapt, reproduce, repurpose, republish, transmit, or distribute any part of the Site;</li>
                            <li>rent, lease, assign or transfer any part of the Site, or otherwise permit third parties to access and use the Site;</li>
                            <li>remove, alter, or obscure any proprietary notices on the Site;</li>
                            <li>probe, scan, or test the vulnerability of the Site or any network connected to the Site, or breach the security or authentication measures on the Site or any network connected to the Site;</li>
                            <li>collect, harvest, reverse look-up, trace, or otherwise seek to obtain any information on any other user of or visitor to the Site;</li>
                            <li>take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Site or any systems or networks connected to the Site;</li>
                            <li>forge headers, impersonate a person, or otherwise manipulate identifiers in order to disguise your identity or the origin of any message or Submission you send to Digital Universe Technologies Inc. on or through the Site.</li>
                        </ol>
                    </div>
                    <div className="static-content__box">
                        <h2>10. Feedback and Submissions.</h2>
                        <p>Any ideas, suggestions, or feedback that you provide to Digital Universe Technologies Inc relating to the Site or business will be exclusively owned by Digital Universe Technologies Inc. You agree that: (a) all information you provide on or through a Site (each, a “Submission”) will be true, accurate and complete; (b) you will not impersonate any person or misrepresent any affiliation; (c) Digital Universe Technologies Inc. is entitled to rely on each Submission; (d) you will only include the personal information of another individual in a Submission if you have the express permission of that individual or if you are otherwise entitled to do so at law; and (e) you have obtained at your own expense all necessary consents, rights and permissions required to grant to Digital Universe Technologies Inc the license to Submissions described below. By making a Submission, you thereby grant to Digital Universe Technologies Inc. an irrevocable, unlimited, sublicensable, transferable, royalty-free, worldwide, perpetual license to use, copy, distribute, modify, make derivative works from, and disclose the information and any other content in the Submission, for any purpose whatsoever including commercial purposes, and you hereby waive all related moral rights or warrant that such rights have been waived by any third-party holder. However, any personal information that is included in a Submission will only be used and disclosed in accordance with Digital Universe Technologies Inc. Privacy Statement. Digital Universe Technologies Inc. may in its discretion screen, edit, refuse to post, refuse to reply to, and remove any Submissions, in whole or in part, from the Site.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>11. No Liability for Third-Party Site.</h2>
                        <p>Your use of any of any third-party business, content, website or application (even if referred to or linked on the Site) is at your own risk, and Digital Universe Technologies Inc. expressly disclaims all liability in connection with any of them</p>
                    </div>
                    <div className="static-content__box">
                        <h2>12. Digital Wallet.</h2>
                        <p>You may need to register for a Digital Wallet to use certain features of the Site. Digital Universe Technologies Inc. may reject, or require that you change your Digital Wallet, or other information that you provide to Digital Universe Technologies Inc. for that purpose. You, and not Digital Universe Technologies Inc, are responsible for any use or misuse of your authentication details. In particular, it is your sole responsibility to: (a) maintain the confidentiality of your authentication details; and (b) promptly notify Digital Universe Technologies Inc immediately if you become aware of, or suspect, any unauthorized use of your Digital Wallet or any breach of security.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>13. Disclaimer of Warranties.</h2>
                        <p>Although Digital Universe Technologies Inc. makes reasonable efforts to ensure that information presented on the Site is accurate, the Site, including all content on the Site and any services made available through the Site, are provided “AS IS” and “AS AVAILABLE”, and may include errors, omissions, or other inaccuracies. Your use of the Site is at your own risk. Digital Universe Technologies Inc. disclaims all warranties, representations, covenants and conditions in connection with the Site, including any warranties, representations, covenants or conditions of merchantability, fitness for a particular purpose, accuracy, completeness, performance, or non-infringement. Digital Universe Technologies Inc. makes no representations or warranties about any third-party websites or related content directly or indirectly accessed through links in the Site. Your sole and exclusive remedy for dissatisfaction with the Site is to stop using the Site</p>
                    </div>
                    <div className="static-content__box">
                        <h2>14. Limitation of Liability.</h2>
                        <p>In addition to any other limitation of Digital Universe Technologies Inc’s. liability, in no event will Digital Universe Technologies Inc (or Digital Universe Technologies directors, officers, employees, service providers, subsidiaries and agents (the “kryp.to Parties”)) be liable to you for any indirect, incidental, special, consequential, punitive, exemplary or aggravated damages, or for any loss of revenue, goodwill or reputation whatsoever, based on any legal theory (including tort or negligence), and even if advised of the possibility of those damages.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>15. Indemnity.</h2>
                        <p>You will indemnify and hold Digital Universe Technologies Inc and the kryp.to Parties harmless from all claims, damages, losses, liabilities, costs and expenses (collectively “Losses”) arising from your: (a) use of the Site; (b) breach of any of the Terms; or (c) untrue, inaccurate, or incomplete Submissions. For the purpose of enforcing this indemnity, you acknowledge and agree that Digital Universe Technologies Inc. may act as agent and trustee for the kryp.to Parties.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>16. Governing Law & Jurisdiction.</h2>
                        <p>The Terms will be governed by the laws of the Province of British Columbia and the federal laws of Canada applicable therein, without reference to any conflict of law rules that might apply the laws of any other jurisdiction. You and Digital Universe Technologies Inc. each attorn to the exclusive jurisdiction of the courts of the Province of British Columbia and all courts competent to hear appeals therefrom to settle any dispute or claim that arises out of or in connection with the Terms or their subject matter or formation (including non-contractual disputes or claims).  Notwithstanding the foregoing, (a) you agree that Digital Universe Technologies Inc. shall be entitled to seek and be awarded an injunction or other appropriate equitable relief from a court of competent jurisdiction anywhere in the world restraining any breach, threatened or actual, of your obligations under any provision of the Terms, and (b) you agree that Digital Universe Technologies Inc. shall be entitled to seek and be awarded an order from a court of competent jurisdiction anywhere in the world for the purpose of recognizing and enforcing any interim or final judgement, order, injunction, award or other relief granted or provided by the courts of British Columbia , and you hereby waive any defence you might then have to the granting of such an order.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>17. Injunction.</h2>
                        <p>You acknowledge that any breach, threatened or actual, of the Terms will cause irreparable harm to Digital Universe Technologies Inc., such harm would not be quantifiable in monetary damages, and Digital Universe Technologies Inc. would not have an adequate remedy at law. You agree that Digital Universe Technologies Inc. shall be entitled, in addition to other available remedies, to seek and be awarded an injunction or other appropriate equitable relief from a court of competent jurisdiction anywhere in the world restraining any breach, threatened or actual, of your obligations under any provision of the Terms, and without the necessity of showing or proving any actual or threatened damage or harm, notwithstanding any rule of law or equity to the contrary. You hereby waive any requirement that Digital Universe Technologies Inc. post any bond or other security in the event any injunctive or equitable relief is sought by or awarded to Digital Universe Technologies Inc. to enforce any provision of the Terms.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>18. Severability.</h2>
                        <p>If any provision of these Terms of Use is determined by a court with jurisdiction to be unlawful, void or unenforceable, then that provision shall be deemed severed from the remaining provisions and shall not affect the validity and enforceability of the remaining provisions.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>19. Notices.</h2>
                        <p>Unless expressly otherwise set out, each notice under the Terms must be in writing, and must be delivered to the other party.  Each party’s addresses for delivery of notices are as follows, unless otherwise notified by that party:</p>
                    </div>
                    <div className="static-content__terms">
                        <div className="static-content__terms-col">
                            <p>Notice from you to Digital Universe Technologies Inc.:</p>
                            <p>Attention: Digital Universe Technologies Inc. Legal Department</p>
                            <p>595 Howe St 10th floor, Vancouver, BC V6C 2T5</p>
                            <p>Contact: legal@kryp.to</p>
                        </div>
                        <div className="static-content__terms-col">
                            <p>Notice from Digital Universe Technologies Inc. to you:</p>
                            <p>Any address or email address you provided to Digital Universe Technologies Inc., or that is publicly available (through the internet or otherwise).</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Terms;

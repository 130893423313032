import React from 'react';

import BoxBg from '../../components/BoxBg';
import FormBox from '../../components/FormBox';
import Select from '../../components/Select';
import Button from '../../components/Button';

import { months, years, countries } from '../../constants';

import './style.scss';

const Payment = () => {
    return (
        <>
            <BoxBg imageUrl="about-image-heading-bg.png" title="Payment" className="box-bg--about" />

            <form className="payment">
                <div className="payment__wrap">
                    <div className="payment__row">
                        <FormBox defaultField placeholder="Full Name..." id="" />
                    </div>
                    <div className="payment__row">
                        <FormBox defaultField placeholder="Email Address..." id="" />
                    </div>
                    <div className="payment__row">
                        <FormBox defaultField placeholder="Full Address..." id="" />
                    </div>
                    <div className="payment__row">
                        <FormBox defaultField placeholder="City Name..." id="" />
                    </div>
                    <div className="payment__row payment__row--state">
                        <div className="payment__col">
                            <FormBox defaultField placeholder="State / Province..." id="" />
                        </div>
                        <div className="payment__col">
                            <FormBox defaultField placeholder="Zip Code..." id="" />
                        </div>
                    </div>
                    <div className="payment__row">
                        <Select options={countries} />
                    </div>
                    <div className="payment__row payment__row--card">
                        <div className="payment__col">
                            <FormBox defaultField type="number" placeholder="Card number" id="" />
                        </div>
                        <div className="payment__col">
                            <FormBox defaultField type="number" placeholder="CVC" id="" />
                        </div>
                    </div>
                    <div className="payment__row payment__row--date">
                        <div className="payment__col">
                            <Select options={months} />
                        </div>
                        <div className="payment__col">
                            <Select options={years} />
                        </div>
                    </div>
                    <div className="payment__row">
                        <Button className="btn btn--lg refer-friend__button" type="submit" text="Submit" />
                    </div>
                </div>
            </form>
        </>
    )
}

export default Payment;
import React from 'react';

import BoxBg from '../../components/BoxBg';
import BoxText from '../../components/BoxText';
import FormBox from '../../components/FormBox';

import './style.scss';

const Contact = () => {
	return (
		<>
            <BoxBg imageUrl="contact-heading-bg.png" title="Contact" className="box-bg--contact" />

            <div className="contact">
                <div className="container">
                    <div className="contact__wrap">
                        <div className="contact__col-lg">
                            <form>
                                <div className="contact__row">
                                    <FormBox label="Name" id="input-name" />
                                </div>
                                <div className="contact__row">
                                    <FormBox label="Email" type="email" id="input-email" />
                                </div>
                                <div className="contact__row">
                                    <FormBox label="How can we help" id="input-description" textarea />
                                </div>
                                <div className="contact__row contact__row--btn">
                                    <button className="btn btn--lg contact__button" type="submit">Submit</button>
                                    <p className="contact__support">Or contact us at <a href="mailto:hello@kryp.to">hello@kryp.to</a></p>
                                </div>
                            </form>
                        </div>
                        <div className="contact__col-sm" style={{ backgroundImage: `url(./images/contact-bg.png)` }} />
                    </div>
                </div>
            </div>
        
            <BoxText>
                <p className="mobile-text-header"><img src="./images/heading.svg" className="krypto6home ml-0" alt="what is krypto 6" /> is a remote first company, our team is global and concentrated in Vancouver, Canada.</p>
            </BoxText>
			
		</>
	)
}

export default Contact;

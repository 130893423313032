import React from 'react';
import classNames from 'classnames';

import './style.scss';

const ColumnsRow = ({ className, content1, content2, imageUrl }) => {
    const style = imageUrl ? { backgroundImage: `url(./images/${imageUrl})` } : null;

    return (
        <div className={classNames('columns-row', className)}>
            <div className="container">
                <div className="columns-row__wrap">
                    <div className="columns-row__col">
                        {content1 ? content1 : null}
                    </div>
                    <div className="columns-row__col" style={style}>
                        {content2 ? content2 : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ColumnsRow;

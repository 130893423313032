import React, { useEffect } from 'react';

import BoxBg from '../../components/BoxBg';
import ColumnsRow from '../../components/ColumnsRow';
import PartnersSlider from '../../components/PartnersSlider';
import Select from '../../components/Select';
import BoxText from '../../components/BoxText';

import { currency } from '../../constants';

import { Wallet } from '../../WalletAdapter.js';

import './style.scss';

import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

const Contributions = () => {

	useEffect(() => {
		document.querySelector('#btn-contribute .wallet-adapter-button').innerHTML = `
			<img src="./images/contribute.png" alt="contribute by krypto"/>
		`;
		document.querySelector('#btn-contribute2 .wallet-adapter-button').innerHTML = `
			<img src="./images/contribute.png" alt="contribute by krypto"/>
		`;
	}, []);

	return (
		<>
			<BoxBg imageUrl="contributions-image-heading-bg.png" title="Contribute" className="box-bg--contributions" />
		
			<div className="contributions-jackpot contributions-jackpot--cols">
				<div className="container">
					<div className="contributions-jackpot__outer">
						<div className="contributions-jackpot__info">
							Real time allocations
						</div>
						<div className="contributions-jackpot__box">
							<div className="contributions-jackpot__label">Total contributions made</div>
							<div className="contributions-jackpot__wrap">
								<div className="contributions-jackpot__value">13,049,450.95</div>
								<div className="contributions-jackpot__select">
									<Select options={currency} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ColumnsRow
				content1={
					<>
						<h2 className="columns-row__title">SIX Contributions:</h2>
						<div className="columns-row__text">
							<p>
								A minimum 50% of your contribution will be allocated in real-time to charitable organizations.  A maximum of 34% will go to the kryp.to rewards pool.
							</p>
							<p>
								Our kryp.to community will continually evaluate the allocation ratio between the charitable organizations and the rewards pool, determining when to increase the ratio to charities.
							</p>

							<div id="btn-contribute">
								<WalletModalProvider><WalletMultiButton /></WalletModalProvider>
							</div>

						</div>
					</>
				}
				content2={
					<>
						<h2 className="columns-row__title">VIA Contributions: </h2>
						<div className="columns-row__text">
							<p>
								Contributions will be allocated in real-time to charitable organizations per VIA experience. VIA experience partner will determine the allocation percentage to charity.
							</p>
							<p>
								e.g. VIA experience partner contributes the entire fiat or digital currency value of the experience (minus kryp.to fees) to a charitable organization. 
							</p>

							<div id="btn-contribute2">
								<WalletModalProvider><WalletMultiButton /></WalletModalProvider>
							</div>
							
						</div>
					</>
				}
			/>

			<BoxText>
				<p>
					The kryp.to PLEDGE provides our community winners a real-time option to contribute all or part of their rewards to our beneficiaries list and/or a charity of their choice.
				</p>
				<p>Learn more ways to <a href="pledge" className='bold-text'>PLEDGE</a></p>
			</BoxText>
		
			<div className="contributions">
				<div className="container">
					<div className="contributions__outer">
						<PartnersSlider />
						<h2 className="contributions__title">Rewards & Winners</h2>
						<div className="contributions__box">
							
						</div>
					</div>
				</div>
			</div>

		</>
	)
}

export default Contributions;

import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

const ArrowLink = ({ to, text }) => {
    return (
        <Link to={to} className="arrow-link">
            <span className="arrow-link__icon">
                <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.34 34.236L31.16 24.84H9.092V21.24H31.16L22.34 11.844V9.504H24.896L37.964 23.04L24.896 36.576H22.34V34.236Z" fill="currentColor"/>
                    <circle cx="23" cy="23" r="21.5" stroke="currentColor" strokeWidth="3"/>
                </svg>
            </span>
            <span className="arrow-link__text">{text}</span>
        </Link>
    )
}

export default ArrowLink;

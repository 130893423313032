import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import './index.scss';
import reportWebVitals from './reportWebVitals';

import App from './App.js'

import store from './store'

import { StytchProvider } from '@stytch/stytch-react';
import { StytchUIClient } from '@stytch/vanilla-js';

const stytch = new StytchUIClient('public-token-test-c3df3700-2d19-44cd-8891-ba5bc3d7df78');


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StytchProvider stytch={stytch}>
        <App />
      </StytchProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

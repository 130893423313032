import React from 'react';
import { Routes, Route } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import NotFoundLayout from '../layout/NotFoundLayout';
import NotFound from './NotFound';
import Home from './Home';
import About from './About';
import CharityNomination from './CharityNomination';
import Contact from './Contact';
import Contributions from './Contributions';
import HowItWorks from './HowItWorks';
import Merch from './Merch';
import Referral from './Referral';
import Six from './Six';
import Via from './Via';
import Payment from './Payment';
import FAQ from './FAQ';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import LoggedIn from './LoggedIn';
import News from './News';
import Ventures from './Ventures';
import Pledge from './Pledge';
import LogIn from './LogIn';
import Dashboard from './Dashboard';
import Love from './Love';
import Partners from './Partners';
import Proceeds from './Proceeds';
import Winners from './Winners';
import PreviousResults from './Winners/PreviousResults';
import {Authenticate} from './LogIn/authenticate'

import ComKids from './Charity/comkids';
import Sno from './Charity/sno';
import FuckCancer from './Charity/FuckCancer';
import Mission44 from './Charity/Mission44';
import AokiFoundation from './Charity/AokiFoundation';
import NuFoundation from './Charity/NuFoundation';
import ForThePlanet from './Charity/ForThePlanet';
import TheOceanCleanup from './Charity/TheOceanCleanup';
import StompOutBullying from './Charity/StompOutBullying';
import BCCFH from './Charity/BCCFH';
import WorldCentralKitchen from './Charity/WorldCentralKitchen';
import ShaqFoundation from './Charity/ShaqFoundation';
import Core from './Charity/Core';
import NBACares from './Charity/NBACares';
import EatLearnPlay from './Charity/EatLearnPlay';
import HockeyCanada from './Charity/HockeyCanada';
import PrideFoundation from './Charity/PrideFoundation';
import ClimateRealityProject from './Charity/ClimateRealityProject';
import NBAFoundation from './Charity/NBAFoundation';
import SKEP from './Charity/SKEP';
import CharityWater from './Charity/CharityWater';
import LebronJamesFoundation from './Charity/LebronJamesFoundation';
import KSE from './Charity/KSE';
import Baby2Baby from './Charity/Baby2Baby';
import SolanaFoundation from './Charity/SolanaFoundation';
import OutdoorAfro from './Charity/OutdoorAfro';
import TheGroceryFoundation from './Charity/TheGroceryFoundation';
import MuskFoundation from './Charity/MuskFoundation';
import L40Foundation from './Charity/L40Foundation';
import LeanIn from './Charity/LeanIn';
import HopeChicago from './Charity/HopeChicago';
import OptionB from './Charity/OptionB';
import NPCF from './Charity/NPCF';
import FoodBanksCanada from './Charity/FoodBanksCanada';
import BigGreen from './Charity/BigGreen';
import CMNH from './Charity/CMNH';
import EightySevenRunning from './Charity/EightySevenRunning';
import TheBirthdayWishProject from './Charity/TheBirthdayWishProject';
import PCMF from './Charity/PCMF';
import WomenAndYouth from './Charity/WomenAndYouth';
import CanPR from './CanPR';
import Bet99 from './Bet99';
import HowToContribute from './HowToContribute';
import HTCcomkids from './HowToContribute/ComKids';



const Pages = () => (
	<Routes>
        <Route element={<NotFoundLayout />}>
            <Route path='*' element={<NotFound />} />
        </Route>
        <Route element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="charity-nomination" element={<CharityNomination />} />
            <Route path="contact" element={<Contact />} />
            <Route path="contributions" element={<Contributions />} />
            <Route path="how-it-works" element={<HowItWorks />} />
            <Route path="merch" element={<Merch />} />
            <Route path="referral" element={<Referral />} />
            <Route path="six" element={<Six />} />
            <Route path="via" element={<Via />} />
            <Route path="payment" element={<Payment />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="logged-in" element={<LoggedIn />} />
            <Route path="news" element={<News />} />
            <Route path="ventures" element={<Ventures />} />
            <Route path="pledge" element={<Pledge />} />
            <Route path="login" element={<LogIn />} />
            <Route path="loggedin" element={<LoggedIn />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="love" element={<Love />} />
            <Route path="partners" element={<Partners />} />
            <Route path="proceeds" element={<Proceeds />} />
            <Route path="winners" element={<Winners />} />
            <Route path="previous-results" element={<PreviousResults />} />
            <Route path="authenticate" element={<Authenticate />} />

            {/* below here are partners */}
            <Route path="comkids" element={<ComKids />} />
            <Route path="sno" element={<Sno />} />
            <Route path="fcancer" element={<FuckCancer />} />
            <Route path="mission44" element={<Mission44 />} />
            <Route path="aokifoundation" element={<AokiFoundation />} />
            <Route path="nufoundation" element={<NuFoundation />} />
            <Route path="fortheplanet" element={<ForThePlanet />} />
            <Route path="theoceancleanup" element={<TheOceanCleanup />} />
            <Route path="stompoutbullying" element={<StompOutBullying />} />
            <Route path="bccfh" element={<BCCFH />} />
            <Route path="worldcentralkitchen" element={<WorldCentralKitchen />} />
            <Route path="shaqfoundation" element={<ShaqFoundation />} />
            <Route path="core" element={<Core />} />
            <Route path="nbacares" element={<NBACares />} />
            <Route path="eatlearnplay" element={<EatLearnPlay />} />
            <Route path="hockeycanada" element={<HockeyCanada />} />
            <Route path="pridefoundation" element={<PrideFoundation />} />
            <Route path="climaterealityproject" element={<ClimateRealityProject />} />
            <Route path="nbafoundation" element={<NBAFoundation />} />
            <Route path="skep" element={<SKEP />} />
            <Route path="charitywater" element={<CharityWater />} />
            <Route path="lebronjamesfoundation" element={<LebronJamesFoundation />} />
            <Route path="kse" element={<KSE />} />
            <Route path="baby2baby" element={<Baby2Baby />} />
            <Route path="solanafoundation" element={<SolanaFoundation />} />
            <Route path="outdoorafro" element={<OutdoorAfro />} />
            <Route path="thegroceryfoundation" element={<TheGroceryFoundation />} />
            <Route path="muskfoundation" element={<MuskFoundation />} />
            <Route path="l40foundation" element={<L40Foundation />} />
            <Route path="leanin" element={<LeanIn />} />
            <Route path="hopechicago" element={<HopeChicago />} />
            <Route path="optionb" element={<OptionB />} />
            <Route path="npcf" element={<NPCF />} />
            <Route path="foodbankscanada" element={<FoodBanksCanada />} />
            <Route path="biggreen" element={<BigGreen />} />
            <Route path="cmnh" element={<CMNH />} />
            <Route path="87Running" element={<EightySevenRunning />} />
            <Route path="thebirthdaywishproject" element={<TheBirthdayWishProject />} />
            <Route path="pcmf" element={<PCMF />} />
            <Route path="women-and-youth-foundation" element={<WomenAndYouth />} />
            
            {/* below here are individual pages */}
            <Route path="canpr" element={<CanPR />} />
            <Route path="bet99" element={<Bet99 />} />

            {/* below here are how to contribute pages */}
            <Route path="how-to-contribute" element={<HowToContribute />} />
            <Route path="how-to-contribute-comkids" element={<HTCcomkids />} />

        </Route> 
	</Routes>
);

export default Pages;

import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'

import BoxBg from '../../components/BoxBg';
import ColumnsRow from '../../components/ColumnsRow';
import BoxText from '../../components/BoxText';
import Select from '../../components/Select';

import classNames from 'classnames';

import { InputNumber } from 'antd';

import { ethers } from "ethers"

import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
	WalletConnectButton,
	WalletModalButton,
	WalletModalProvider,
	WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import {
	DonateButton
} from './TXn'
import store from '../../store';
import { setValues, setAddress, setReferral } from '../../defaultSlice'

import axios from 'axios';

import { useStytchSession } from '@stytch/stytch-react';

const cryptoType = [
	{
		id: 1,
		value: 'SOL',
		text: 'SOL'
	},
	{
		id: 2,
		value: 'ETH',
		text: 'ETH'
	},
];

const currency = [
  { id: 1, value: 'usd_total', text: 'USD' },
  { id: 2, value: 'sol_total', text: 'SOL' },
  { id: 5, value: 'eth_total', text: 'ETH' }
];


const LoggedIn = () => {
	const { connection } = useConnection();
	const wallet = useWallet();

	const session = useStytchSession()

	let address = useSelector((state) => state.default.address);

	const [type, setType] = useState('SOL');
	const [amount, setAmount] = useState('10');
	const [claimable, setClaimable] = useState(0);
	const [trigger, setTrigger] = useState(true);
	const [contributions, setContributions] = useState({SOL: 0, ETH: 0});
	const [referral, setReferral] = useState('none')

	const [_currency, setCurrency] = useState('usd_total')
  	const [charityCurrency, setCharityCurrency] = useState('usd_total')
	const [polled, setPolled] = useState(false)
	const [compound, setCompound] = useState({
		sol_total: 0,
		eth_total: 0,
		usd_total: 0
	})

	if(!polled) {
		setPolled(true)
		axios.post('https://smartpaywebhook.justusmoeller2.repl.co/get_compound', {}).then(res => {
				console.log(res.data)
		  setCompound(res.data.this)
		})
	}

	const [assignTickets, setAssignTickets] = useState('true')
	const changeAssignTickets = (e) => {
		setAssignTickets(e.target.value)
	}

	const changeCurrency = (e) => {
		console.log(e.target.value)
		setCurrency(e.target.value)
	}
	
	  const changeCharityCurrency = (e) => {
		setCharityCurrency(e.target.value)
	  }

	const [prices, setPrices] = useState({
		resp: false,
		BTC: 0.000054,
		ETH: 0.0035,
		SOL: 0.32
	})

	function changeReferral(e) {
		console.log(e.target.value)
		setReferral(e.target.value)
	}

	function onChange(value) {
		setAmount(value)
	}

	function changeType(e) {
		console.log(e.target.value)
		setType(e.target.value)
	}


	const [userdata, setUserdata] = useState({tickets: [], april: {tickets: []}, april_sixteen: {tickets: []}, may: {tickets: []}, june: {tickets: []}, july: {tickets: []}, august: {tickets: []} })
	const [pricingData, setPricingData] = useState({
		bitcoin: {usd: 0},
		ethereum: {usd: 0},
		solana: {usd: 0}
	})



	const refresh = () => {
		window.location = window.location.href
	}

	useEffect(() => {
		if(trigger) {
			axios.post('https://smartpaywebhook.justusmoeller2.repl.co/create_user', {
				email: session.authentication_factors[0].email_factor.email_address
			}).then(res => {
				setUserdata(res.data.user)
				setPricingData(res.data.pricing)
				console.log(res.data)

				let tickets = 0
				tickets += parseInt(((res.data.user.ETH_donations*10000-res.data.user.ETH_used*10000)/prices.ETH/10000).toFixed(0))
				tickets += parseInt(((res.data.user.SOL_donations*10000-res.data.user.SOL_used*10000)/prices.SOL/10000).toFixed(0))
				console.log(tickets)
				setClaimable(parseInt(tickets))
				setContributions({
					SOL: res.data.user.SOL_donations,
					ETH: res.data.user.ETH_donations
				})

				setTrigger(false)
			})
		}

	}, []);


	useLayoutEffect(() => {
        document.body.classList.add("loggedin-pg-style");

		return () => {
			document.body.classList.remove('loggedin-pg-style');
		};
    }, []);

	if (session === null) window.location = '/login'
	if (new Date(session.expires_at) < new Date()) {window.location = '/login'} else {}
	

	return <div>
		<BoxBg imageUrl="how-it-works-heading-bg.png" title="next draw coming soon" className="box-bg--how-it-works" />
	</div>

	// <BoxText><WalletModalProvider><WalletMultiButton /></WalletModalProvider></BoxText>
	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="Contribute" className="box-bg--how-it-works" />

			{
				false ?
			<div /> :

			<>
			{/*}
			<BoxText className="make-contribution wallet-connection">
				<div className="section-02">
					<form className="payment">
						<div className="payment__wrap"><h3 className="src-text">We Will Resume Receiving Donations Soon </h3></div>
					</form>
				</div>
			</BoxText> 
			*/}
			
			<BoxText className="make-contribution wallet-connection dd-select-contribution-style">

				<div className="section-02">
					<form className="payment">
						<div className="payment__wrap">
							<div className="payment__row">
								<h3>Select currency: </h3>
								<br />
								<Select options={cryptoType} onChange={changeType} />
								<p>Select the currency you want to contribute</p>
								{
									false ? <div><h3>Wallet address:</h3>
									<AddressBox id="wallet-address" /></div> : <></>
								}

								<h3>Amount of tickets:</h3>
								<InputNumber
									defaultValue="10"
									min="1"
									//max="10"
									step="1"
									precision={0}
									onChange={onChange}
									stringMode
								/>
								<p>1 Ticket = $5</p>

								<div className="set-row">
									<div className="set-col set-gap set-col-mobile-01">
										<h3>Referral Code</h3>
										<Select className="ref-code-select" options={[
											{id: 1,value: 'No',text: 'None'},
											{id: 2, value: 'COMKIDS', text: 'ComKids'},
											{id: 2,value: 'SMRT',text: 'SMRT'}]} 
											onChange={changeReferral}
										/>
									</div>
									<div className="set-col set-col-mobile-02">
										<h3>Claim Tickets </h3>
										<Select className="ref-code-select" options={[ 
											{id: 1, value: true, text: 'Yes'}, 
											{id: 2, value: false, text: 'No'}]} 
											onChange={changeAssignTickets} 
										/>
									</div>
								</div>
									
							</div>
							{
								type == 'ETH' ? 
								<div>
									<DonateETH amount={5/pricingData.ethereum.usd*amount} tickets={amount} assignTickets={assignTickets} referral={referral} email={session.authentication_factors[0].email_factor.email_address} /> <p className="sm-text-note">The pop-up could take up to 5 seconds to appear to approve your contribution</p>
								</div> :
								<div>
									<DonateSOL
										amount={5/pricingData.solana.usd*amount}
										tickets={amount}
										email={session.authentication_factors[0].email_factor.email_address}
										trigger={setTrigger}
										assignTickets={assignTickets}
										referral={referral}
									/>
									<p className="sm-text-note">The pop-up could take up to 5 seconds to appear to approve your contribution</p>
								</div>
							}
							
							{
								true ? <div /> : <button className="btn btn--lg refer-friend__button">Donate</button>
							}
							
							{ true ? <div /> :
							<div className='wallet-connection'>
								<ReferralBox id="wallet-address" />
							</div>
							}
						</div>
					</form>
				</div>
			</BoxText> 
			

			<ColumnsRow
			className="columns-row--type13 section-50"
			content1={
				<>
					<h2 className="columns-row__title text-style-05">
						Total contributions made:
					</h2>
					
					<div className="columns-row__text">
						<span class="total">{contributions.ETH.toFixed(4) + ' ETH'}</span>
					</div>
					<br /><br />
					<div className="columns-row__text">
						<span class="total">{contributions.SOL.toFixed(4) + ' SOL'}</span>
					</div>
					<br /><br />
					<div className='refresh-btn'> 
						<button className="btn btn--lg refer-friend__button w-auto bold-text" onClick={refresh}>Refresh</button>
						<p className="sm-text-note">Refresh to update Contribution totals and rewards</p>
					</div>
				</>
			}
			content2={
				<>
						<div className="six-jackpot__label">Real-time <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> rewards</div>
						<div className="six-jackpot__wrap margin-auto">
							<div className="six-jackpot__value">{_currency == 'usd_total' ? '$ ' + (compound[_currency]*.4).toFixed(2) : (compound[_currency]*.4).toFixed(4)}</div>
							<div className="six-jackpot__select">
							<Select options={currency} onChange={changeCurrency} />
							</div>
						</div>
						<br/><br/>
					<div className="six-jackpot__label">Contributions to charities</div>
					<div className="six-jackpot__wrap  margin-auto">
							<div className="six-jackpot__value">{charityCurrency == 'usd_total' ? '$ ' + (compound[charityCurrency]*.5).toFixed(2) : (compound[charityCurrency]*.5).toFixed(4)}</div>
							<div className="six-jackpot__select">
							<Select options={currency} onChange={changeCharityCurrency} />
							</div>
					</div>
				</>
			}
			/>
			<TicketComponents userdata={userdata}/>
			</>
			}
		</>
	)
}

export default LoggedIn;

const TicketComponents = (props) => {
	console.log(props)
	let ticket_rows = []
	return <> 
	
			{ true ? <div /> : 
				<BoxText>
					<p>
						Rewards/Tickets will take a few minutes to appear after the contribution has been received & validated on the blockchain
					</p>
				</BoxText>
			}
			

			<ColumnsRow
			className="columns-row--type12"
			content1={
				<>
					{props.userdata.hasOwnProperty('september') ? <TicketsSection tickets={props.userdata.september.tickets} date={'September 9, 2023'} /> : <div /> }
					{props.userdata.hasOwnProperty('august') ? <TicketsSection tickets={props.userdata.august.tickets} date={'August 5, 2023'} /> : <div /> }
					{props.userdata.hasOwnProperty('july') ? <TicketsSection tickets={props.userdata.july.tickets} date={'July 8, 2023'} /> : <div /> }
					{props.userdata.hasOwnProperty('june') ? <TicketsSection tickets={props.userdata.june.tickets} date={'June 10, 2023'} /> : <div /> }
					{props.userdata.hasOwnProperty('may') ? <TicketsSection tickets={props.userdata.may.tickets} date={'May 11, 2023'} /> : <div /> }
					{props.userdata.hasOwnProperty('april') ? <TicketsSection tickets={props.userdata.april.tickets} date={'April 13, 2023'} /> : <div /> }
					{props.userdata.hasOwnProperty('april_sixteen') ? <TicketsSection tickets={props.userdata.april_sixteen.tickets} date={'March 16, 2023'} /> : <div /> }
				</>
			}
		/>

	</>
}

const AddressBox = ({ label, placeholder, type = 'text', id, textarea, defaultField, ...rest }) => {
	function changeAddress(e) {
		store.dispatch(setAddress(e.target.value))
	}

    return (
        <>
            {textarea ? (
                <div className="form-box form-box--textarea">
                    <textarea className="form-box__field" id={id} placeholder={placeholder} {...rest}></textarea>
                    {label ? <label className="form-box__label" for={id}>{label}</label> : null}
                </div>
            ) : (
                <div className={classNames('form-box',  { 'form-box--default': defaultField } )}>
                    {label ? <label className="form-box__label" for={id}>{label}</label> : null}
                    <input type={type} className="form-box__field" id={id} placeholder={placeholder} {...rest} onChange={changeAddress} />
                </div>
            )}
        </>
    )
}

const ReferralBox = ({ label, placeholder, type = 'text', id, textarea, defaultField, ...rest }) => {
	
	let storedReferral = useSelector((state) => state.default.referral);

	const [referral, _setReferral] = useState(false);
	const [checked, setChecked] = useState(false);

	if (!checked) {
		setChecked(true)
		axios.post('http://68.183.113.182/referral_set', {
			//own_address: publicKey.toString(),
			own_address: 'username'
		}).then(res => {
			_setReferral(res.data.pubkey)
		})
	}

	function submitReferral(e) {
		_setReferral(true)
		axios.post('http://68.183.113.182/set_referral', {
			//own_address: publicKey.toString(),
			own_address: 'username',
			referral: storedReferral
		}).then(res => {
			_setReferral(res.data)
		})
	}

	function changeReferral(e) {
		console.log(e.target.value)
		store.dispatch(setReferral(e.target.value))
	}

	if (referral) {
		return <div className="section-01 thank-you-page-content">
			<div className="six-jackpot__select">
				<p>	
					<span className='contained'><span className='krypto-address'>{referral}</span>
					
					</span> 
				</p> 
			</div>
		</div>
	}

    return (
        <>
            {textarea ? (
                <div className="form-box form-box--textarea">
                    <textarea className="form-box__field" id={id} placeholder={placeholder} {...rest}></textarea>
                    {label ? <label className="form-box__label" for={id}>{label}</label> : null}
                </div>
            ) : (
                <div className={classNames('form-box',  { 'form-box--default': defaultField } )}>
                    {label ? <label className="form-box__label" for={id}>{label}</label> : null}
                    <input type={type} className="form-box__field" id={id} placeholder={placeholder} {...rest} onChange={changeReferral} />
                </div>
            )}
			<button className="btn btn--lg refer-friend__button" onClick={submitReferral} >Submit</button>
        </>
    )
}

const DonateETH = (props) => {
	console.log(props)
	const requestAccount = async () => {

		if (!window.ethereum) {
		  throw new Error("No crypto wallet found. Please install it.");
		}
		const accounts = await window.ethereum.send("eth_requestAccounts");
		const account = accounts.result[0];

		const provider = new ethers.providers.Web3Provider(window.ethereum)

		// MetaMask requires requesting permission to connect users accounts
		await provider.send("eth_requestAccounts", []);

		// The MetaMask plugin also allows signing transactions to
		// send ether and pay to change state within the blockchain.
		// For this, you need the account signer...
		const signer = provider.getSigner()

		const balance = await provider.getBalance("ethers.eth")
		
		const tx = await signer.sendTransaction({
			to: "0x2f34D2B7DcF9Cb539734389AC62E2d91a8147AB7",
			value: ethers.utils.parseEther(props.amount.toFixed(7))
		});
		

		axios.post('https://smartpaywebhook.justusmoeller2.repl.co/donation_request', {
			type: 'ETH',
			amount: props.amount.toFixed(7),
			own_address: account,
            tx: tx.hash,
			email: props.email,
			tickets: props.tickets,
			assign_tickets: props.assignTickets,
			referral: props.referral
		}).then(res => {
			console.log(res)
		})
		
	};


	const req = (e) => {
		e.preventDefault()
		requestAccount()
	}

	return <div> <button className="btn btn--lg refer-friend__button" onClick={req}>Contribute with ETH wallet</button></div>
}

const DonateSOL = (props) => {
	const { connection } = useConnection();
    const wallet = useWallet();
    const { publicKey, sendTransaction } = useWallet();

	return  <WalletModalProvider>
				
				{ /* Your app's components go here, nested within the context providers. */ }
				{ !publicKey ? <WalletMultiButton /> : <DonateButton assignTickets={props.assignTickets} email={props.email} amount={props.amount.toFixed(5)} setTrigger={props.trigger} tickets={props.tickets} referral={props.referral} />}
			</WalletModalProvider>
}


const TicketsSection = (props) => {
	const rows = []
	if(!props.tickets) return <div />

	let x = []
	props.tickets.forEach(ticket => {
		x = [ticket, ...x]
	})

	x.forEach(row => {
		rows.push(<TicketRow numbers={row} />)
	})

	let left_row = []
	if(!x.length) {
		left_row = [<span>-</span>,
			<span>-</span>,
			<span>-</span>,
			<span>-</span>,
			<span>-</span>,
			<span>-</span>,
			<span>-</span>]
	} else {
		let elements = []
		x[0].forEach(item => {
			let x = item.toString()
			if (x.length == 1) {
				x = '0'+item.toString()
			}
			elements.push(<span>{x}</span>)
		})
		left_row = elements
	}
	

	return <div className="tickets-section">

	<div className="ticket" id='jump_tickets'>
		<div className="ticket-header">
			<p>
				<span>Draw date:</span> {props.date}
			</p>
		</div>
		<div className="ticket-details">
			<img src="./images/six.svg" alt="six ticket" class="ticket-img" />
			<p>
				<img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> numbers
			</p>
			<p className="ticket-numbers bordered">
				{left_row}
			</p>

			
									

			<img src="./images/mascot.svg" alt="mascot" className="mascot page-mascot" />
			<div><p className="ticket-guaranteed">
					Philanthropy that is 
				</p><p className="ticket-guaranteed" style={{paddingTop: '0px'}}>
				Inclusive and Aspirational
				<br/>
				<img src="./images/heading.svg" className="krypto6home mt-10" alt="what is krypto 6" />
				</p>
				{
					false ? <p className="ticket-guaranteed-number" style={{paddingTop: '0px'}}>
					Inclusive and Aspirational
					<br/>
					<img src="./images/heading.svg" className="krypto6home mt-10" alt="what is krypto 6" />
					</p> : <div />
				}
			</div>
		</div>
	</div>

	<div className="ticket">
		<div className="ticket-header">
			<p>
				<span>Total tickets:</span> {props.tickets.length}
			</p>
			<p>
				<span>Draw date:</span> {props.date}
			</p>
		</div>
		<div className="ticket-details all-tickets">
			{rows}
		</div>
		{
			props.tickets.length > 8 ? 
			<div className="more-indicator">
				<img src="./images/krypto-elephant.png" alt="mascot" className="tix-logo" />
			</div>
			: <div />
		}
		
	</div>

	
</div>
}

const TicketRow = (props) => {

	let elements = []
	let numbers = props.numbers.sort(function(a, b) {
		return a - b;
	});
	numbers.forEach(item => {
		let x = item.toString()
		if (x.length == 1) {
			x = '0'+item.toString()
		}
		elements.push(<span>{x}</span>)
	})
	let row = <p className="ticket-numbers">{elements}</p>
	return row
}


const EventTicket = () => {
	return <div className="ticket event-ticket">
	<div className="ticket-header">
		<img src="./images/heading.svg" alt="kryp.to" className="ticket-logo via-logo" />
	</div>
	<div className="ticket-details event-details">
		<img src="./images/via.svg" alt="via ticket" class="ticket-img" />
		<p>
			<b>Event:</b> <br />
			Drake live in Vegas at the Wynn
		</p>
		<p>
			<b>Contribution amount:</b> <br />
			$1,000 USD
		</p>
		<p>
			<b>Access level:</b> <br />
			VIP stage area etc
		</p>
		<p>
			<b>Unique/Authentic ID:</b> <br />
			to verify contribution/access
		</p>
		<p>
			<a href="/" target="_blank" className="bold-text">View e-ticket</a>
		</p>
	</div>
</div>
}
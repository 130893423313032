import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from "./components/scrollToTop";

import { Wallet } from './WalletAdapter.js'

function App() {
  	return (
    	<Router>
			<ScrollToTop />
			<Wallet />
    	</Router>
  	);
}

export default App;

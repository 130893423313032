import React, { useLayoutEffect } from 'react';

import BoxBg from '../../components/BoxBg';
// import ColumnsRow from '../../components/ColumnsRow';
import BoxText from '../../components/BoxText';

// import './style.scss';

const Winners = () => {

	useLayoutEffect(() => {
        document.body.classList.add("winners-page");

		return () => {
			document.body.classList.remove('winners-page');
		};
    }, []);

	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="Winners" className="box-bg--how-it-works" />

			<BoxText className="m-02 winners-section-01">
				<div className="columns-row__text">
					<div className="winners-content">
						<h1>
							<img src="./images/krypto6.png" alt="krypto 6" className="krypto6" /> <br className='mobile-only'/> Winning Numbers
						</h1>
						<h2 style={{ fontWeight: '800'}}>
							Saturday, September 9th 2023
						</h2>
						<div className='winning-numbers'>
							<span>9</span>
							<span>11</span>
							<span>14</span>
							<span>23</span>
							<span>35</span>
							<span>36</span>
						</div>
						<div className="btn-p">
							<a href="previous-results" className="bold-text prev-btn">
								View Previous Results
							</a>
						</div>
						<div className='rewards-pool'>
							<p>
								Rewards Pool Value
							</p>
							<h3>
								$303.96
							</h3>
						</div>
					</div>
				</div>
			</BoxText>

			<BoxText className="m-02 winners-lists winners-section-02">
				<div className='tickets-section winners-section'>
					<div class="ticket winner-list">
						<div class="ticket-header">
							<p>
								<span>Grand Prize Winner(s)</span>
								<br/>
								Match: 6/6
							</p>
						</div>
						<div class="winners">
							<div className='winner'>
								<p className='w-address'>
									0xe3...0d6a
									<br/>
								</p>
								<span className='w-amount'>
									$303.96
								</span>
							</div>
						</div>
					</div>
					{/*}
					<div class="ticket winner-list">
						<div class="ticket-header">
							<p>
								<span>Winners</span>
								<br/>
								Match: 5/6+ 
							</p>
						</div>
						<div class="winners">
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
						</div>
					</div>
					<div class="ticket winner-list">
						<div class="ticket-header">
							<p>
								<span>Winners</span>
								<br/>
								Match: 5/6 
							</p>
						</div>
						<div class="winners">
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
							<div className='winner'>
								<p className='w-address'>
									fabuwfgwu7tr783rt2uih
									<br/>
									<span className='w-amount'>
										$42.50
									</span>
								</p>
							</div>
						</div>
					</div>
			*/}
				</div>
			</BoxText>

			<BoxText className="m-02 winners-section-03">
				<div className="columns-row__text">
					<h3 style={{ fontWeight: '800'}}>
						If you didn't receive one of the rewards above, our ALWAYS-WIN technology will provide you a FREE ticket to the next draw. 
					</h3>
					<p className="mb-45">
						<br/>
						This complimentary <img src="./images/krypto6.svg" className="krypto6home" alt="what is krypto 6" /> ticket has one lifecycle.
					</p>
				</div>
			</BoxText>

			<BoxBg className="six-bg-01" imageUrl="six.svg" />

		</>
	)
}

export default Winners;

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './style.scss';

const Button = ({ link, text, className, ...rest }) => {
    return (
        <>
            {link
                ? <Link className={classNames('btn', className)} to={link} {...rest}>{text}</Link>
                : <button className={classNames('btn', className)} {...rest}>{text}</button>
            }
        </>
    )
}

export default Button;

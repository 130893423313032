import React from 'react';

import BoxBg from '../../components/BoxBg';
import FormBox from '../../components/FormBox';

import './style.scss';

const Referral = () => {
	return (
		<>
            <BoxBg imageUrl="referral-image-heading-bg.png" title="Refer a friend" className="box-bg--referral" />
        
            <div className="refer-friend">
                <div className="container">
                    <div className="refer-friend__wrap">
                        <div className="refer-friend__col-lg">
                            <form>
                                <div className="refer-friend__row">
                                    <FormBox label="First Name" id="input-first-name" required />
                                </div>
                                <div className="refer-friend__row">
                                    <FormBox label="Last Name" id="input-last-name" required />
                                </div>
                                <div className="refer-friend__row">
                                    <FormBox type="email" label="Email" id="input-email" required />
                                </div>
                                <div className="refer-friend__row">
                                    <FormBox label="Referral email" id="input-referral-email" required />
                                </div>
                                <div className="refer-friend__row">
                                    <FormBox label="Wallet Address" id="input-wallet-address" />
                                </div>
                                <div className="refer-friend__row refer-friend__row--btn">
                                    <button className="btn btn--lg refer-friend__button" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div className="refer-friend__col-sm" style={{ backgroundImage: `url(./images/referral-image-bg.svg)` }} />
                    </div>
                </div>
            </div>
		</>
	)
}

export default Referral;

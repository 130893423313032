import React, { useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";

import BoxBg from '../../components/BoxBg';
import BoxText from '../../components/BoxText';
import ColumnsRow from '../../components/ColumnsRow';


const NBACares = () => {

  useLayoutEffect(() => {
    document.body.classList.add("charity-pg-style");

    return () => {
    document.body.classList.remove('charity-pg-style');
    };
  }, []);

	const navigate = useNavigate();

  const reroute = () => {
    navigate("/logged-in");
  };

	return (
		<>
			<ColumnsRow
				className="columns-row--type09 kryptophant mobile-col-reverse-01"
				content1={
					<>
						<img src="./images/slider/nba.png" alt="NBA Cares" className="charity-logo mobile-center" style={{ width: '200px', height: 'auto', marginBottom: '35px', filter: 'grayscale(1) brightness(0.95)', objectPosition: 'left'}} />
            <div className="columns-row__text">
              <h2 class="charity-text mobile-center">
								Support NBA Cares by making a contribution!
              </h2>
            </div>
            <div className="columns-row__box mobile-center">
              <button
                className="btn btn--lg main-btn"
                type="submit"
                onClick={reroute}
              >
                Contribute Now
              </button>
            </div>
					</>
				}
				imageUrl="mascot.svg"
			/>

		</>
	)
}

export default NBACares;


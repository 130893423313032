import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const initialState = {
  type: 'SOL',
  amount: 1,
  referral: null
};

const defaultSlice = createSlice({
  name: 'default',
  initialState,
  reducers: {
    setValues(state, action) {
        console.log(action.payload)
      state.type = action.payload.type;
      state.amount = action.payload.amount
    },
    setAddress(state, action) {
      state.address = action.payload
    },
    setReferral(state, action) {
        state.referral = action.payload
    }
  },
});

export const {
  setValues,
  setReferral,
  setAddress
} = defaultSlice.actions;

export default defaultSlice.reducer;

import React from 'react';

import BoxBg from '../../components/BoxBg';
import BoxText from '../../components/BoxText';

import './style.scss';


const Ventures = () => {
	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="News" className="box-bg--how-it-works" />

			<BoxText className="m-02">
				<p className="mobile-text-header">
					Coming soon
					<br className="mobile-only"/>
					<img src="./images/mascot.svg" alt="mascot" className="mascot page-mascot" />
				</p>
			</BoxText>

		</>
	)
}

export default Ventures;

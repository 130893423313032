import React from 'react';
import classNames from 'classnames';

import './style.scss';

const Switch = ({ onChange, checked, size }) => {
    return (
        <label className={classNames('switch', { 'switch--sm': size === 'sm' })}>
            <input type="checkbox" id="theme-switch" onChange={onChange} checked={checked} />
            <span></span>
        </label>
    )
}

export default Switch;

import React from 'react';

import BoxBg from '../../components/BoxBg';
import BoxText from '../../components/BoxText';
import ColumnsRow from '../../components/ColumnsRow';

import './style.scss';


const Ventures = () => {
	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="Ventures" className="box-bg--how-it-works" />

			<BoxText>
				<p className="bold-font">
					Venture Capital with PURPOSE
				</p>
			</BoxText>

			<ColumnsRow
				className="columns-row--type09 kryptophant"
				content1={
					<>
						<div className="columns-row__text">
							<p>
								We use proceeds generated from the <img src="./images/krypto6.svg" className="krypto6home sm-krypto6 " alt="what is krypto 6" /> to invest across all stages and sectors, with a focus on Web3, distributed ledger technology and disruptive innovation.
								We actively pursue partnerships that enhance virtual and real-world experiences. 
								<br /><br />
								At <img src="./images/heading.svg" className="krypto6home krypto-img-sm" alt="what is krypto 6" /> VENTURES we enjoy evaluating compelling ideas and partnership opportunities.
							</p>
						</div>
					</>
				}
				imageUrl="mascot.svg"
			/>

			<ColumnsRow
				className="columns-row--type11 link-style"
				content1={
					<>
						<p className="text-left">
							Please include a clear description that will help us evaluate your opportunity.
							<br /><br />
							Get in touch at <a href="mailto:ventures@kryp.to">ventures@kryp.to</a>
						</p>
					</>
				}
				imageUrl="about-decor-bg.svg"
			/>

			<div className="contributions ventures-page">
				<div className="container">
					<div className="contributions__outer">
						<h2 className="contributions__title">
							VENTURES
						</h2>
						<div className="contributions__box ventures-logo-section">
							<a href="https://www.risenshine.app/" target="_blank" rel="noreferrer">
								<img src="./images/slider/rs.webp" alt="rise + shine" className="logo-rsnew" loading="lazy" />
							</a>
							<p>
								Rise+Shine and Electronic Arts (EA) share the belief that youth empowerment doesn’t need to be boring. The EA community is sharing their expertise to help Rise+Shine develop a gamification strategy that will enable users to earn and unlock real-world opportunities, rewards, and incentives as they level-up in the app.
							</p>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}

export default Ventures;

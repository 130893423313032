import React from 'react';
import classNames from 'classnames';

import './style.scss';

const BoxBg = ({ imageUrl, title, className }) => {
    return (
        <div
            className={classNames('box-bg', className, { 'box-bg--head': !!title })}
            style={{ backgroundImage: `url(../images/${imageUrl})` }}
        >
            {title && <h1 className="box-bg__title">{title}</h1>}
        </div>
    )
};

export default BoxBg;

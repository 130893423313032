import React from 'react';

import BoxBg from '../../components/BoxBg';
import FormBox from '../../components/FormBox';
import ColumnsRow from '../../components/ColumnsRow';
import SocialIcons from '../../components/SocialIcons';

import './style.scss';

const Merch = () => {
	return (
		<>
			<BoxBg imageUrl="about-image-heading-bg.png" title="Merch" className="box-bg--merch" />

			<ColumnsRow
				className="columns-row--type11"
				content1={
					<>
						<h2 className="columns-row__title">Follow us on social media for the latest news and <img src="./images/heading.svg" className="krypto6home" alt="what is krypto 6" /> prize giveaways</h2>
						<SocialIcons size="lg" />
					</>
				}
				imageUrl="about-decor-bg.svg"
			/>
		
			<div className="merch">
				<div className="container">
					<div className="merch__wrap">
						<div className="merch__col-lg">
							<form>
								<div className="merch__row merch__row--text">
									Join our newsletter
								</div>
								<div className="merch__row">
									<FormBox label="Email" type="email" id="input-email" />
								</div>
								<div className="merch__row merch__row--btn">
									<button className="btn btn--lg merch__button" type="submit">Submit</button>
								</div>
							</form>
						</div>
						<div className="merch__col-sm" style={{ backgroundImage: `url(./images/merch-image-bg.png)` }} />
					</div>
				</div>
			</div>
		</>
	)
}

export default Merch;

import React, { useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";

import BoxBg from '../../components/BoxBg';
import ColumnsRow from '../../components/ColumnsRow';

import './style.scss';

const Love = () => {

	const navigate = useNavigate();

	const reroute = () => {
        navigate('/logged-in')
    }

	return (
		<>
			<BoxBg imageUrl="how-it-works-heading-bg.png" title="Love" className="box-bg--how-it-works" />

			<ColumnsRow
				className="columns-row--type09 kryptophant love-nft"
				content1={
					<>
						<div className="columns-row__text">
							<h2 className="columns-row__title">
								<b>LOVE</b> <br/>
								by Thomas Hussung
							</h2>
							<p>
								German Pop Artist, Thomas Hussung crosses lines between the pop art world of Andy Warhol and Damien Hirst along with other very well known contemporary pop artists.
								<br/><br/>
								Thomas Hussung is well known for his very Warhol-like portraits of Che Guevara, "Message" and "Brand" art.
								<br/><br/>
								Make a contribution to receive a complimentary LOVE NFT by Thomas Hussung. One lucky contributor will be rewarded with the original LOVE canvas.
							</p>
						</div>
						<div className="columns-row__box">
							<button className="btn btn--lg main-btn" type="submit" onClick={reroute}>Make a Contribution</button>
						</div>
					</>
				}
				imageUrl="love.jpeg"
			/>

		</>
	)
}

export default Love;

import React from 'react';
import classNames from 'classnames';

import './style.scss';

const BoxText = ({ children, className }) => {
    return (
        <div class={classNames('box-text', className)}>
            <div className="container">
                <div className="box-text__wrap">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default BoxText;

import React from 'react';

import BoxBg from '../../components/BoxBg';

import './style.scss';

const PrivacyPolicy = () => {
    return (
        <>
            <BoxBg imageUrl="about-image-heading-bg.png" title="Privacy policy" className="box-bg--about" />
            <div className="container">
                <div className="static-content">
                    <div className="static-content__box">
                        <h2>Digital Universe Technologies Inc. d/b/a kryp.to</h2>
                        <p>Digital Universe Technologies Inc. ("kryp.to") is committed to responsible information management practices. Any personal information provided to us is collected, used, disclosed and stored in accordance with the Freedom of Information and Protection of Privacy Act ("FIPPA"), British Columbia and other relevant legislation.</p>
                        <p>So that you can make informed choices, this Privacy Statement describes how kryp.to collects and uses your personal information through the kryp.to website (“Site”), devices, products, services, and applications that reference this Privacy Statement ("kryp.to Services”). The terms described in the Terms of Use also apply to this Privacy Statement. By using kryp.to Services you are consenting to the practices described in this Privacy Statement.</p>
                        <p>kryp.to does not collect information through the Site that personally identifies individuals except when individuals provide this information voluntarily by e-mail, live chat, participating in a survey or contest or by requesting a particular product or service from kryp.to and/or Digital Universe Technologies Inc.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Information sharing with Third Parties</h2>
                        <p>We will share your personal information with third parties only in the ways that are described in this Privacy Statement.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Aggregate information (non-personal)</h2>
                        <p>We may share aggregated demographic information, such as age group and postal code or zip code, about our user base with third-party companies that provide marketing services to kryp.to</p>
                        <p>This information does not identify individual users and is only used for the purpose of selecting target audiences for marketing campaigns.</p>
                        <p>We do not link aggregate user data with personal information.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Personal information</h2>
                        <p>We may provide your personal information to companies that provide services to help us with our business activities, such as conducting a survey or contest or to send emails or special offers to you.</p>
                        <p>For all third parties, we will only share the information necessary for these third parties to provide a product or service on our behalf. These companies are authorized to collect and use your personal information only as necessary.</p>
                        <p>Further, we may also disclose your personal information:</p>
                        <ul>
                            <li>as required by law;</li>
                            <li>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request, or;</li>
                            <li>to any other third party with your prior consent to do so.</li>
                        </ul>
                    </div>
                    <div className="static-content__box">
                        <h2>Marketing choices</h2>
                        <p>If you no longer wish to receive our promotional communications, you may opt-out of receiving them by:</p>
                        <ul>
                            <li>following the unsubscribe link included in each communication;</li>
                            <li>contacting Customer Support by email: hello@kryp.to</li>
                        </ul>
                        <p>By unsubscribing you will no longer receive promotional emails from kryp.to and/or Digital Universe Technologies Inc.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Surveys or contests</h2>
                        <p>From time to time, we may provide you the opportunity to participate in contests or surveys on our Site. Participation is completely voluntary and you therefore have a choice whether or not to disclose any of your personal information. The requested information typically includes contact information (such as name, email address, phone number), your personal opinion, and demographic information (such as postal code or zip code and age group).</p>
                        <p>We use survey information to improve our products and services and track and target the interests of our global community so that we can enhance the user experience.</p>
                        <p>In the case of contests, information is collected and used in order to administer the contest, improve products and services, contact the winners to award their rewards and publish winners’ name, photograph, location and prize. Each contest will have its own Contest Conditions, which includes a privacy notice specific to that contest. </p>
                    </div>
                    <div className="static-content__box">
                        <h2>Log files</h2>
                        <p>As is true of most websites, we gather certain information automatically and store it in log files. This information includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data.</p>
                        <p>We use this information, which does not identify individual users, to analyze trends, to administer the Site, to track users' movements around the Site, and to gather demographic information about our user base as a whole.</p>
                        <p>We use a third-party tracking service that uses tracking technologies (cookies and web beacons) to track non-personal information about visitors to our Site in the aggregate, and to track usage and volume statistics to improve our services and our Site.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Cookies and other Technologies</h2>
                        <p>A cookie is a small text file that is stored on a user's computer for record-keeping purposes. We do not link the information we store in cookies to any personal information you submit while on our Site.</p>
                        <p>We use both session ID cookies and persistent cookies. A session ID cookie expires when you close your browser whereas a persistent cookie remains on your hard drive for an extended period of time.</p>
                        <p>We utilize cookies to help manage our Site and to protect against security issues. We do not set a persistent cookie to store your passwords, as we do not want anyone to be able to access your account other than you. Cookies are intended to enhance your experience on the Site and the administration of the Site.</p>
                        <p>We use Google Optimize (GO) to perform variation testing. GO cookies are placed within our Site’ pages to determine page effectiveness in conversion. The results from this testing are used to create an improved product experience for our players.</p>
                        <p>GO does not collect personal information, only information related to the session. In the event that personal information is collected, Google requests your consent before sharing it. Further details can be found in the  Google Privacy Policy.</p>
                        <p>We use a web analytics service to help us research issues on the Site and improve our visitors’ experiences. When enabled, it records mouse clicks, mouse movements, scrolling activity and may record text you type, excluding text input in password fields.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Behavioural targeting</h2>
                        <p>We partner with third-party marketing and advertising organizations, to either display advertising on our Site or to manage our advertising on other websites. Our third-party partners may use technologies, such as cookies and eTags, to gather information about your activities on the Site in order to provide you with advertising based upon your browsing activities and interests. If you do not wish to have this information used for the purpose of serving you interest-based advertising, you may opt-out here.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Web beacons</h2>
                        <p>Web beacons are tiny graphics with a unique identifier that are embedded invisibly on pages on the Site. We use this tool to help us understand site usage, e.g. which pages are being accessed more frequently. This helps us to better manage content on the Site. We do not identify individuals when using web beacons on pages on the Site. </p>
                        <p>We also use web beacons in our HTML-based emails and newsletters to let us know which emails have been opened by recipients. This allows us to gauge the effectiveness of our marketing campaigns and make improvements where necessary."</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Links to other sites</h2>
                        <p>Our Site may contain links to other sites that are not owned or controlled by Digital Universe Technologies Inc. Please be aware that Digital Universe Technologies Inc. is not responsible for the privacy practices of such other sites.</p>
                        <p>We encourage you to read the privacy statements of each and every website that collects personal information.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Social media features</h2>
                        <p>Our Site may include social media features, such as the Facebook Like button and Twitter Tweet button. These features may collect your IP address and which page you are visiting, and may set a cookie to enable the feature to function properly. Social media features are hosted by a third party, such as Twitter and Facebook. Your interactions with these features are governed by the privacy policy of the company providing it.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Winner stories</h2>
                        <p>We post winner stories that may contain personal information, such as name and country or city of residence.</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Security</h2>
                        <p>We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. However, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>
                        <p>When you enter sensitive information, such as a credit card number, we encrypt the transmission of that information using Transport Layer Security (TLS).</p>
                    </div>
                    <div className="static-content__box">
                        <h2>Contacting us</h2>
                        <p>If you would like more information about our privacy practices, please contact us by email: hello@kryp.to</p>
                        <p>From time to time, we may update this Privacy Statement to reflect changes to our information practices.</p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PrivacyPolicy;
